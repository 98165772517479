import styled from "styled-components";

import {
  colors,
  IMAC_BREAKPOINT,
  IPAD_PRO_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { Container } from "@util/standard";

export const HeadWrapper = styled.div<{ hasScrolled: boolean }>`
  width: 100%;
  position: fixed;
  background-color: ${colors.transparent};
  z-index: 10;
  height: 61px;

  @keyframes fade-in {
    100% {
      background-color: ${colors.white};
    }
  }
  @keyframes fade-out {
    100% {
      background-color: ${colors.transparent};
    }
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 70px;
  }

  ${(props) =>
    props.hasScrolled
      ? `animation: fade-in 0.5s forwards;`
      : `animation: fade-out 0.5s forwards;`}
`;

export const TopContainer = styled(Container)<{
  hasScrolled: boolean;
  blackBackground: boolean;
}>`
  width: 100%;
  height: 34px;

  justify-content: flex-end;
  align-items: center;
  background: ${({ blackBackground }) =>
    blackBackground ? `${colors.black}` : `${colors.white}`};
  ${(props) => (props.hasScrolled ? ` display: none;` : ` display: flex;`)}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

export const HeaderContainer = styled(Container)<{ hasScrolled: boolean }>`
  padding: 0px 30px;
  justify-content: space-between;
  margin: 0 auto;
  height: 61px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: ${(props) => (props.hasScrolled ? `5px 20px` : `0px 30px`)};
  }
`;

export const DesktopLinksContainer = styled(Container)`
  margin: 0;
  width: auto;
  align-items: center;

  .link {
    padding-left: 40px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

export const LogoContainer = styled(Container)`
  align-items: center;
  cursor: pointer;
  width: 5%;
  @media only screen and (min-width: ${IMAC_BREAKPOINT}px) {
    width: 4%;
  }
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 10%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 20%;
  }
`;

export const MobileContainer = styled(Container)`
  display: none;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: flex;
  }
`;

export const GetInTouchContainer = styled(Container)`
  flex-direction: column;
  background-color: ${colors.darkGrey};
  width: 100%;
  padding: 120px 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  h1,
  h2,
  h3,
  h4,
  p {
    color: white;
    width: 48%;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 55px 0;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    h1,
    h2,
    h3,
    h4,
    p {
      width: 70%;
    }
  }
`;

export const InfoContainer = styled(Container)`
  flex-direction: row;
  height: 100%;
  justify-content: flex-end;
  align-items: baseline;
  column-gap: 80px;
  padding: 0 80px;
  flex-flow: wrap;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 100%;
    padding: 15px;
    justify-content: flex-start;
  }
`;

export const SubInfoContainer = styled(Container)`
  margin: 50px 0;
  column-gap: 80px;
  flex-flow: wrap;

  .links {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    /* margin: 37px -85px 37px 0; */
    flex-direction: row;
    gap: 30px;
  }
`;

export const SubscribeContainer = styled(Container)`
  flex-direction: column;
  background-color: ${colors.blackGrey};
  width: 40%;
  justify-content: center;
  align-items: center;
  p {
    color: ${colors.white};
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    height: 100%;
  }
`;

export const InfoWithNewsletter = styled(Container)`
  flex-direction: row;
  background-color: ${colors.footerGrey};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
  }
`;
