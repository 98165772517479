import React, { useRef } from "react";
import styled from "styled-components";
import ReactSlick from "react-slick";

import { SanityCarousel } from "@graphql-types";
import { Container, P } from "@util/standard";
import { colors, fontWeights, IPAD_PRO_BREAKPOINT, MOBILE_BREAKPOINT } from "@util/constants";
import { BlocksContent, Carousel } from "@global";

const Wrapper = styled(Container) <{ height?: string }>`
  width: 100%;
  margin: auto;
  height: ${(props) => (props.height ? props.height : "52vh")};
  align-items: center;
  background-color: ${colors.carouselBlack};
  .slick-dots {
    display: flex;
    justify-content: center;
    position: relative;
  }
  .slick-dots li.slick-active button:before {
    color: white;
  }
  .slick-dots li button:before {
    color: white;
    font-size: 7px;
  }
  .slick-dots li {
    margin: 0;
  }
  .slick-slider {
    width: 100%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 442px;
  }
`;

const CarouselWrapper = styled(Container)`
  display: flex !important;
  justify-content: center;
  text-align: center;
  width: 70% !important;
  margin: -50px auto auto auto;
  outline: none;
  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${colors.white};
  }
  h1,h2,h3,h4,h5 {
    font-size: 105px;
    font-weight: ${fontWeights.bold};
    line-height: 60px;
    margin: 70px 0 60px 0;
  }
  p {
    font-size: 20px;
  }

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    h1,h2,h3,h4,h5{
      font-size: 66px;
      line-height: 42px;
    }
    p {
      font-size: 14px;
    }
  }
`;

interface Props {
  data: SanityCarousel;
  height?: string;
  statSlider?: boolean;
}

const DefaultCarousel = ({ data, height, statSlider }: Props) => {
  const { carousel } = data;
  const refCarousel = useRef<ReactSlick>(null);

  const handleAfterChange = (index: number) => {
    if (refCarousel?.current == null) return;
    if (refCarousel.current.props == null) return;

    const childrenNumber = refCarousel.current.props.children?.length - 1;

    if (index === childrenNumber) {
      refCarousel.current.slickPause;
      setTimeout(() => {
        if (refCarousel?.current == null) return;
        refCarousel.current.slickGoTo(0);
        refCarousel.current.slickPlay;
      }, refCarousel.current.props.autoplaySpeed);
    }
  };

  return (
    <Wrapper height={height}>
      <Carousel
        sliderRef={refCarousel}
        hideArrow
        autoplaySpeed={statSlider === true ? 3000 : 10000}
        adaptiveHeight={!Boolean(statSlider)}
        infinite={!Boolean(statSlider)}
        afterChange={statSlider === true ? handleAfterChange : undefined}>
        {carousel?.map((content) => {
          if (content == null) {
            return <P color="errorRed">Error loading content</P>;
          }
          return (
            <CarouselWrapper key={content?._key}>
              <BlocksContent blocks={content?._rawColumnContent} />
            </CarouselWrapper>
          );
        })}
      </Carousel>
    </Wrapper>
  );
};

export default DefaultCarousel;
