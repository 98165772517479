import React from "react";
import HubspotForm from "react-hubspot-form";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import { useLocation } from "@reach/router";

import { useStore } from "@state/store";
import { useFooterQuery } from "@api";
import { Button, Container, P } from "@util/standard";
import {
  BlocksContent,
  ColorContent,
  Link,
  Image,
  CarouselWithLogo,
} from "@global";
import {
  GetInTouchContainer,
  InfoContainer,
  InfoWithNewsletter,
  SubInfoContainer,
  SubscribeContainer,
} from "./shared.styles";

const Address = styled(Container)`
  flex-direction: column;
  P {
    margin-block-end: 0em;
    margin-block-start: 5px;
  }
`;

const Footer = () => {
  const {
    region: { iban },
  } = useStore();

  const {
    allSanityFooter: { nodes },
    allSanityContact: { nodes: contactNodes },
  } = useFooterQuery();

  const selected = nodes.filter((node) => node.region?.iban === iban);
  const selectedContact = contactNodes.filter(
    (node) => node.region?.iban === iban
  );
  const socialIcon = selectedContact && selectedContact[0].socialMediaIcon;

  const {
    footerLinks,
    quickLinks,
    getInTouchContent,
    location,
    region,
    logoCarousel,
  } = selected[0];
  const urlLocation = useLocation();

  if (region == null) return null;

  const { newsletterForm } = region;

  const InfoBlock = () => {
    return (
      <InfoContainer>
        <SubInfoContainer>
          <div className="links">
            <P fontWeight="bold" margin="0 0 8px 0">
              Services
            </P>
            {footerLinks?.map((footerLink) => {
              if (footerLink == null) {
                return null;
              }
              return (
                <Link {...footerLink} hoverUnderlined key={footerLink?._key} />
              );
            })}
          </div>
          <div className="links">
            <P fontWeight="bold" margin="0 0 8px 0">
              QuickLinks
            </P>
            {quickLinks?.map((quickLink) => {
              if (quickLink == null) return null;
              return (
                <Link {...quickLink} hoverUnderlined key={quickLink?._key} />
              );
            })}
          </div>
        </SubInfoContainer>

        <Container flexDirection="column">
          <P fontWeight="bold">Locations</P>
          <Address>
            {location?.map((address) => {
              if (!address) return null;

              return (
                <Container
                  key={address._key}
                  margin="0 0 20px 0"
                  mobileMargin="0 0 8px 0"
                >
                  <BlocksContent blocks={address?._rawColumnContent} />
                </Container>
              );
            })}
          </Address>
        </Container>
      </InfoContainer>
    );
  };

  const Subscribe = () => (
    <SubscribeContainer>
      <Container flexDirection="column" margin="40px auto">
        <P>Subscribe to our newsletter</P>
        <Container>
          {newsletterForm && (
            <HubspotForm
              portalId={newsletterForm.portalId}
              formId={newsletterForm.formId}
              loading={<div>Loading form...</div>}
            />
          )}
        </Container>
        {socialIcon && socialIcon.length > 0 && (
          <Container flexDirection="row">
            {socialIcon?.map((image, index) => {
              if (!image) return null;

              return (
                <Container margin="10px 10px 0 0" key={index}>
                  <a
                    href={image.mediaIcon?.buttonLinks?.url as string}
                    target="_blank"
                  >
                    <Image
                      image={image.socialIconWhite}
                      width="20px"
                      height="20px"
                    />
                  </a>
                </Container>
              );
            })}
          </Container>
        )}
      </Container>
    </SubscribeContainer>
  );

  return (
    <Container>
      <Subscribe />
      <InfoBlock />
    </Container>
  );
};

export default Footer;
