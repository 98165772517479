import React from "react";
import styled from "styled-components";

import { Container, P } from "@util/standard";
import { SanityCarouselWithButton } from "@graphql-types";
import {
  colorsRGB,
  fontSizes,
  IMAC_BREAKPOINT,
  IPAD_PRO_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { BlocksContent, Carousel, DefaultButton } from "@global";

const Wrapper = styled(Container)`
  width: 100%;
  height: 57vh;
  align-items: center;
  background-color: ${colorsRGB.carouselBlue()};
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 612px;
  }
  .slick-slider {
    width: 100%;
  }
  .slick-prev {
    left: 40px;
    z-index: 2;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      left: -30px;
    }
  }
  .slick-next {
    right: 40px;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      top: 153%;
    }
  }
  .slick-dots {
    position: absolute;
    bottom: -12vh;
    left: 40px;
    display: flex !important;
    @media only screen and (min-width: ${IMAC_BREAKPOINT}px) {
      bottom: -18vh;
    }
    @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
      bottom: -20vh;
    }
    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      bottom: -15vh;
    }
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      bottom: -150px;
    }
  }
  .slick-dots li {
    margin: 0px -3px;
  }
  .slick-dots li button:before {
    font-size: 7px;
  }
`;

const BlocksContentContainer = styled(Container)`
  display: flex !important;
  justify-content: center;
  width: 61% !important;
  margin: auto;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 80% !important;
  }
`;

const ButtonContainer = styled(Container)`
  position: relative;
  right: 190px;
  bottom: -25vh;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    right: 336px;
    top: -260px;
    p {
      font-size: ${fontSizes.p.default}px;
    }
  }
`;

interface Props {
  data: SanityCarouselWithButton;
}

const CarouselWithButton = ({ data }: Props) => {
  const { carousel, buttonLinks } = data;
  return (
    <Wrapper>
      <Carousel autoplaySpeed={5000}>
        {carousel?.map((content) => {
          if (content == null) {
            return <P color="errorRed">Error loading carousel</P>;
          }
          return (
            <BlocksContentContainer key={content?._key}>
              <BlocksContent blocks={content?._rawColumnContent} />
            </BlocksContentContainer>
          );
        })}
      </Carousel>
      {buttonLinks?.url && (
        <ButtonContainer>
          <DefaultButton
            theme="textDark"
            text={`${buttonLinks?.linktext}`}
            linkTo={`${buttonLinks?.url}`}
            padding="10px 40px"
            dimensions={{ height: "46px" }}
            arrowWidth="23px"
          />
        </ButtonContainer>
      )}
    </Wrapper>
  );
};

export default CarouselWithButton;
