import React from "react";
import styled from "styled-components";

import { Container } from "@util/standard";
import { SanityCarousel, SanityCarouselWithParagraph } from "@graphql-types";
import { BlocksContent, DefaultCarousel } from "@global";
import { colorsRGB, IPAD_PRO_BREAKPOINT, MOBILE_BREAKPOINT } from "@util/constants";

const LeftContainer = styled(Container)`
  background-color: ${colorsRGB.lightGrey()};
  align-items: center;
  width: 40%;
  h4 {
    line-height: 32.2px;
  }
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 100%;
    h4 {
      line-height: 28px;
    }
  }
`;

const Wrapper = styled(Container)`
  flex-direction: row;
  min-height: 83vh;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    flex-direction: column;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    min-height: 100vh;
  }
`;

const BlocksContentContainer = styled(Container)`
  width: 50%;
  margin: auto;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    height: 46vh;
    width: 62%;
    align-items: center;
  }
`;

const CarouselContainer = styled(Container)`
  width: 60%;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    height: 55vh;
    width: 100%;
  }
`;

interface Props {
  data: SanityCarouselWithParagraph;
}

const CarouselWithParagraph = ({ data }: Props) => {
  const { textContent } = data;

  return (
    <Wrapper>
      <LeftContainer>
        <BlocksContentContainer >
          <BlocksContent
            blocks={textContent?._rawColumnContent}
          />
        </BlocksContentContainer>
      </LeftContainer>
      <CarouselContainer width="60%">
        <DefaultCarousel statSlider data={data as SanityCarousel} height="100%" />
      </CarouselContainer>
    </Wrapper>
  );
};

export default CarouselWithParagraph;
