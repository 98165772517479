import React from "react";
import HubspotForm from "react-hubspot-form";
import styled from "styled-components";

import { useRegionQuery } from "@api";
import { Container } from "@util/standard";
import { assets, colors, CONTENT_MAX_WIDTH } from "@util/constants";
import { Maybe, SanityGetInTouchBlock, SanitySocialIcon } from "@graphql-types";
import { ColorContent, Image } from "@global";

const FormContainer = styled(Container)`
  && {
    width: 100%;
    * {
      box-sizing: border-box;
    }
    div {
      width: 100%;
      margin-right: 0 !important;
      .actions {
        input,
        select {
          width: 100%;
          color: ${colors.white};
          background-color: ${colors.white};
        }
      }
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      label {
        color: red;
      }
    }

    input,
    select {
      width: 100%;
      height: 52px;
      border-radius: 10px;
      border: 0px solid ${colors.black};
      background-color: ${colors.white};
      color: ${colors.white};
      font-family: "Rubik";
      padding: 0 20px;
      &:focus {
        outline: 0;
      }
      &::-webkit-input-placeholder {
        font-family: "Rubik";
      }
    }

    select {
      appearance: none;
      position: relative;
      background-image: url(${assets.arrowDownDark});
      background-size: 10px;
      background-repeat: no-repeat;
      background-position: calc(100% - 20px) center;
    }

    fieldset {
      max-width: none;
      width: calc(100% + 14px);
      margin-right: -14px;
      .field {
        padding-right: 14px;
        margin-bottom: 14px;
      }
      .hs-input.hs-input {
        width: 100%;
        background-color: ${colors.white};
      }
    }
  }
`;

const Content = styled(Container)`
  width: 100%;
  h1,
  h2,
  h3,
  h4 {
    width: 85%;
  }
`;

interface Props {
  info?: SanityGetInTouchBlock;
  media?: Maybe<SanitySocialIcon>[];
}

function ContactForm({ info, media }: Props) {
  const data = useRegionQuery();

  if (data == null) {
    return null;
  }

  if (data.contactForm == null) {
    return null;
  }

  const { contactForm } = data;

  return (
    <>
      <Container
        width="65%"
        maxWidth={`${CONTENT_MAX_WIDTH}px`}
        margin=" 200px auto 120px auto"
        flexDirection="column"
        tabletWidth="80%"
        mobileMargin="100px auto 100px auto"
      >
        <Content>
          <ColorContent data={info?.blockContent} />
        </Content>
        <Container margin="30px 0 60px 0">
          {media?.map((icon) => {
            if (icon == null) {
              return null;
            }
            const handleClick = () => {
              icon.mediaIcon?.buttonLinks &&
                window.open(`${icon.mediaIcon?.buttonLinks?.url}`, "_blank");
            };

            return (
              <Container
                width="20px"
                height="20px"
                margin="0 15px 0 0"
                key={icon?._key}
                onClick={handleClick}
                cursor="pointer"
              >
                <Image image={icon.mediaIcon} height="100%" width="100%" />
              </Container>
            );
          })}
        </Container>
        <FormContainer>
          {contactForm && (
            <HubspotForm
              portalId={contactForm.portalId}
              formId={contactForm.formId}
              loading={<div>Loading form...</div>}
            />
          )}
        </FormContainer>
      </Container>
    </>
  );
}

export default ContactForm;
