import React from "react";
import styled from "styled-components";

import { Container } from "@util/standard";
import { SanityPartnerBlock } from "@graphql-types";
import {
  IPAD_PRO_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  colorsRGB,
} from "@util/constants";
import { BlocksContent, Image } from "@global";

const Wrapper = styled(Container)<{ partnerBlock?: boolean }>`
  align-items: center;
  margin: ${(props) => (props.partnerBlock ? "auto" : "80px auto")};
  text-align: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) =>
    props.partnerBlock ? `${colorsRGB.lightGrey(0.2)}` : "white"};
  padding: 80px 0;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 35px 0;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    text-align: left;
  }
`;

const ContentContainer = styled(Container)`
  width: 40%;
  justify-content: center;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 50%;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 70%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 66%;
  }
`;

const ImageContainer = styled(Container)<{ partnerBlock?: boolean }>`
  flex-direction: row;
  width: ${(props) => (props.partnerBlock ? "52%" : "40%")};
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: ${(props) => (props.partnerBlock ? "0" : "auto auto auto 30px")};
    flex-wrap: wrap;
    width: 100%;
    ${({ partnerBlock }) =>
      partnerBlock && `display: flex; justify-content: center;`}
  }
`;

const Logo = styled(Container)<{
  hasLink: boolean;
}>`
  width: 100%;
  margin: 30px 30px 10px 30px;
  ${({ hasLink }) =>
    hasLink &&
    `
    cursor: pointer;
  `}
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 120px;
    margin: 15px 10px 10px 20px;
  }
`;

interface Props {
  data: SanityPartnerBlock;
  partnerBlock?: boolean;
}

const SupportBlock = ({ data, partnerBlock }: Props) => {
  const { content, partnerLogos } = data;

  return (
    <Wrapper partnerBlock={partnerBlock}>
      <ContentContainer>
        <BlocksContent blocks={content?._rawColumnContent} />
      </ContentContainer>
      <ImageContainer partnerBlock={partnerBlock}>
        {partnerLogos?.map((logo) => {
          if (logo == null) {
            return null;
          }
          const handleClick = () => {
            if (logo?.buttonLinks?.url) {
              logo?.buttonLinks &&
                window.open(`${logo?.buttonLinks?.url}`, "_blank");
            }
          };
          return (
            <Logo
              key={logo?._key}
              onClick={handleClick}
              hasLink={Boolean(logo?.buttonLinks?.url)}
            >
              <Image
                image={logo}
                objectFit="contain"
                height="100%"
                width="100%"
              />
            </Logo>
          );
        })}
      </ImageContainer>
    </Wrapper>
  );
};

export default SupportBlock;
