import { Maybe, SanityBlogs, SanityRegion } from "@graphql-types";
import { useStore } from "@state/store";
import { useRef, useEffect } from "react";
import { useHasMounted } from "./hooks";
import { ButtonTheme } from "./types";

export function usePrevious<T>(value: T) {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export async function getCountryCode() {
  const result = await (await fetch("https://ipapi.co/json/")).json();

  return (result.country_code as string).toLowerCase();
}

export function objectEquality(object1: any, object2: any) {
  return Object.keys(object1).every((key) => object1[key] === object2[key]);
}

export function determineButtonTheme(
  theme: ButtonTheme,
  isOnDarkBackground?: boolean | undefined
) {
  if (isOnDarkBackground || isOnDarkBackground == null) {
    return theme;
  }
  switch (theme) {
    case "text":
      return "textDark";
    default:
      return theme;
  }
}

export function setBaseUrl(slug?: string, iban?: string) {
  if (slug == null && iban === "nz") {
    return process.env.GATSBY_BASE_URL;
  }

  if (slug == null) {
    return `${process.env.GATSBY_BASE_URL}/${iban}`;
  }

  if (iban === "nz") {
    return `${process.env.GATSBY_BASE_URL}/${slug}`;
  }

  if (iban == null) {
    return `${process.env.GATSBY_BASE_URL}/${slug}`;
  }

  return `${process.env.GATSBY_BASE_URL}/${iban}/${slug}`;
}

export function setSlug(url: string | undefined | null) {
  const hasMounted = useHasMounted();

  if (!hasMounted) {
    return `/${url}`;
  }

  const region = useStore.getState().region.slug;

  if (url == null) {
    return "";
  }

  if (url === "/") {
    return `/${region}`;
  }

  if (region !== "") {
    return `/${region}/${url}`;
  }

  return `/${url}`;
}

export function setRegion(region: SanityRegion) {
  if (region) {
    const { hrefLang, iban } = region;
    const regionToSet = {
      hrefLang: hrefLang as string,
      iban: iban as string,
      slug: iban !== "nz" ? (iban as string) : "",
    };
    useStore.setState({ region: regionToSet });
  }
}

export function filterCorrectBlogs(data: Maybe<SanityBlogs>[]) {
  const {
    region: { iban },
  } = useStore();

  const blogs = data.filter((item) => {
    if (item == null) return null;

    if (item.regions == null || (item.regions && item.regions.length == 0)) {
      return item;
    }

    return item.regions?.find((region) => region?.iban === iban);
  });

  return blogs;
}
