import React, { createElement } from "react";
import styled from "styled-components";
import PortableText from "@sanity/block-content-to-react";

import {
  Maybe,
  SanityBlockContent,
} from "@graphql-types";
import { colors, fontWeights, MOBILE_BREAKPOINT } from "@util/constants";
import { P } from "@util/standard";
import { urlForImageRef } from "@lib/sanityClient";

const Span = styled.span<{
  startHex?: Maybe<string>;
  endHex?: Maybe<string>;
}>`
  display: inline-block;
  width: fit-content;
  background: linear-gradient(
    to left,
    ${props => (props.startHex ? props.startHex : `${colors.AISBlue}`)},
    ${props => (props.endHex ? props.endHex : `${colors.AISBlue}`)}
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Rubik', sans-serif;
  font-weight: ${fontWeights.extrabold};
`;

const Img = styled.img`
  height: 40px;
  width: auto;
  margin-right: 50px;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 20px;
    margin-right: 30px;
  }
`;

interface Props {
  data: Maybe<SanityBlockContent> | undefined;
}

const ColorContent = ({
  data
}: Props) => {
  if (data == null) {
    return null;
  }

  const { _rawContent } = data;

  if (_rawContent == null) {
    return null;
  }

  const color = (props: any) => {
    return (
      <Span startHex={colors.AISBlue} endHex={colors.AISBlue}>
        {props.children}
      </Span>
    );
  };

  const BlockRenderer = (props: any) => {
    const { style = "normal" } = props.node;
    if (/^h\d/.test(style)) {
      const level = style.replace(/[^\d]/g, "");
      return createElement(
        style,
        { className: `heading-${level}` },
        props.children
      );
    }
    return <P>{props.children}</P>;
  };

  const ImageRenderer = (props: any) => {
    return <Img src={urlForImageRef(props.node.asset).url() as string} />;
  };

  return (
    <PortableText
    blocks={_rawContent}
    serializers={{
      marks: { color },
      types: { block: BlockRenderer, image: ImageRenderer },
    }}
    imageOptions={{ auto: "format", fit: "fill" }}
    projectId={process.env.SANITY_PROJECT_ID}
    dataset={process.env.GATSBY_NODE_ENV ?? "development"}
    />
  );
};

export default ColorContent;
