import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";
import { filterCorrectBlogs } from "@util/helper";

const RecentBlogQuery = () => {
  const {
    allSanityBlogs: { nodes },
  }: Query = useStaticQuery(graphql`
    {
      allSanityBlogs(sort: { order: DESC, fields: publishDate }) {
        nodes {
          ...sanityBlogs
        }
      }
    }
  `);

  const blogs = filterCorrectBlogs(nodes);

  return blogs.slice(0, 3);
};

export default RecentBlogQuery;
