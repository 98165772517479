import React from "react";
import styled from "styled-components";

import { Container, P } from "@util/standard";
import { Maybe, SanityJobInfo } from "@graphql-types";
import { colors, colorsRGB, MOBILE_BREAKPOINT } from "@util/constants";
import { BlocksContent, DefaultButton } from "@global";

const Wrapper = styled(Container)`
  height: 890px;
  background: ${colorsRGB.statement(0.15)};
  width: 100%;
  padding: 40px 0;
  flex-direction: column;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    background: ${colors.white};
  }
`;

const Content = styled(Container)`
  width: 80%;
  flex-direction: row;
  margin: auto;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 80%;
  }
`;

const InfoContainer = styled(Container)<{ lastItem?: boolean }>`
  justify-content: flex-start; 
  align-items: flex-start;
  flex-direction: row;
  width: 80%;
  &:before {
    content : "";
    position: absolute;
    bottom  : -1vh;
    height  : 1px;
    width   : 81%;
    border-bottom: ${props => props.lastItem ? "0px":`1px solid ${colors.underlineColor}`};
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

interface Props {
  data: Maybe<SanityJobInfo>[] ;
}

const JobInfo = ({data}: Props) => {
  if(data == null){
    return (<P color="errorRed">Error on Job Info</P>);
  }
  return(
    <Wrapper>
      {data?.map((job, index) => {
        if (job == null) {
          return (<P color="errorRed">Error on Job data rendering</P>);
        }
        return(
          <Content key={job?._key}>
            <InfoContainer lastItem={index == (data?.length - 1)}>
              <Container width="80%" mobileWidth="100%">
                <BlocksContent 
                  blocks={job?.heading?._rawColumnContent}
                />
              </Container>
              <Container width="20%" mobileWidth="100%" justifyContent="flex-end" mobileJustifyContent="flex-start">
                {job?.buttonLink?.url && <DefaultButton 
                  theme="textDark"
                  text={`${job?.buttonLink?.linktext}`}
                  linkTo={`${job?.buttonLink?.url}`}
                  margin="15px 0 0 110px"
                  mobileMargin="10px 0 0 0"
                  padding="10px 40px"
                  dimensions={{ height: "46px" }}
                  arrowWidth="23px"
                />}
              </Container>
            </InfoContainer>
          </Content>
        );
      })}
    </Wrapper>  
  );
};

export default JobInfo;
