import React from "react";
import styled from "styled-components";

import { Container, P } from "@util/standard";
import { Maybe, SanityColumn } from "@graphql-types";
import { colorsRGB, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { BlocksContent, Carousel } from "@global";
import { useStore } from "@state/store";

const Wrapper = styled(Container)`
  display: flex;
  width: 100%;
  height: 83vh;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 460px;
  }
  .slick-slider{
    width: 100%;
  }
  .slick-prev {
    left: 40px;
    z-index: 2;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      left: -30px;
    }
  }
  .slick-next {
    right: 40px;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      top: 90%;
    }
  }
  .slick-dots {
    position: absolute;
    bottom: 33px;
    justify-content: center;
    display: flex !important;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      right: 125px;
    }
  }
  .slick-dots li {
    margin: 0px -3px;
  }
  .slick-dots li button:before {
    font-size: 7px;
  }
`;

const BlocksContentContainer = styled(Container)<{greyBg?: boolean; blueBg?: boolean}>`
  position: relative;
  justify-content: center;
  margin: auto;
  width: 100% !important;
  display: flex !important;
  align-items: center;
  height: 83vh;
  background-color: ${props => props.greyBg ? colorsRGB.squareBlack() : props.blueBg ? colorsRGB.squareBlue() : colorsRGB.squareGrey()};
  p,h1,h2,h3,h4,h5 {
    width: 60%;
    margin: -45px auto 0 auto;
  }
  p { 
    margin-block-start: 20px;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 460px;
    p,h1,h2,h3,h4,h5 {
      width: 75%;
    }
  }
`;
  
interface Props {
  data:  Maybe<SanityColumn>[];
  greyBg?: boolean;
  blueBg?: boolean;
}

interface Column {
  greyBg?: boolean;
  blueBg?: boolean;
}

const QuoteCarousel = ({data,greyBg,blueBg}: Props) => {
  const { screenWidth } = useStore();

  return(
    <Wrapper>
      <Carousel slidesToShow={screenWidth.isLaptopWidth? 2 : 1} slidesToScroll={screenWidth.isLaptopWidth? 2 : 1}>
          {data?.map((content, index) => {
              if (content == null) {
                return <P color="errorRed">Error loading carousel</P>;
              }
              const Column = ({greyBg,blueBg} : Column ) => {
                return(
                  <BlocksContentContainer key={content?._key} greyBg={greyBg} blueBg={blueBg}>
                    <BlocksContent 
                      blocks={content?._rawColumnContent}
                      />
                  </BlocksContentContainer>
                );
              }
              if ( index % 2 == 0 ) {
                return (
                  <Column blueBg={blueBg} key={content?._key}/>
                );
              }
              return(
                <Column greyBg={greyBg} key={content?._key}/>
              );
            })}
      </Carousel>
    </Wrapper>
  );
};

export default QuoteCarousel;
