import React, { ReactNode, useEffect } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";

import { colors } from "@util/constants";
import { usePrevious } from "@util/helper";
import { Color } from "@util/types";

interface Props {
  width?: string;
  children: ReactNode;
  placement: "right" | "left" | "top" | "bottom";
  duration?: number;
  visible: boolean;
  onClose: () => void;
  backgroundColor?: Color;
}

const MenuDrawer = (props: Props) => {
  const prevVisible = usePrevious(props.visible);

  useEffect(() => {
    if (prevVisible !== props.visible) {
      if (props.visible) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "initial";
      }
    }
  }, [props.visible]);

  return (
    <Drawer
      open={props.visible}
      onClose={props.onClose}
      direction={props.placement}
      style={{
        width: "80%" ?? props.width,
        height: "100%",
        backgroundColor: props.backgroundColor
          ? `${colors[props.backgroundColor]}`
          : `${colors.white}`,
      }}
      duration={props.duration}
    >
      {props.children}
    </Drawer>
  );
};

export default MenuDrawer;
