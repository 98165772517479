import React from "react";
import { Link as GatsbyLink } from "gatsby";
import styled from "styled-components";
import { A, P } from "@util/standard";
import { Image } from "@global";
import { colors } from "@util/constants";
import { Color } from "@util/types";
import { setSlug } from "@util/helper";
import { SanityFlightImage } from "@graphql-types";

interface Props {
  linktext?: string | undefined | null;
  url?: string | undefined | null;
  internallink?: boolean | undefined | null;
  newwindow?: boolean | undefined | null;
  asset?: SanityFlightImage | undefined | null;
  className?: string;
  color?: Color;
  isUnderlined?: boolean;
  isCurrent?: boolean;
  isBold?: boolean;
  hoverColor?: Color;
  padding?: string;
  opacity?: number;
  download?: boolean;
  hoverUnderlined?: boolean;
  fontSize?: number;
  onClick?: () => void;
}

const StyledGatsbyLink = styled(GatsbyLink)<{
  padding?: string;
  underlined?: number;
  opacity?: number;
}>`
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  user-select: none;
  ${({ padding }) => padding && `padding: ${padding}`};
  text-decoration: ${(props) =>
    props.underlined === 1 ? `underline` : `none`};
  text-underline-offset: ${(props) =>
    props.underlined === 1 ? `5px` : `none`};
`;

const ImageWrapper = styled.div`
  &:hover {
    opacity: 0.6;
  }
`;

const StyledP = styled(P)<{
  color?: Color;
  hoverColor?: Color;
  download?: boolean;
  isCurrent?: boolean;
  hoverUnderlined?: boolean;
  fontSize?: number;
}>`
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ color }) => color && `color:${colors[color]}`};
  ${({ isCurrent }) => isCurrent && `color:${colors.AISBlue}`};
  ${({ hoverColor }) =>
    hoverColor &&
    `&:hover {
      color: ${colors[hoverColor]};
    }`}
  ${({ hoverUnderlined }) =>
    hoverUnderlined &&
    `&:hover {
      text-decoration: underline  ${colors.AISBlue};
      text-underline-offset: 5px;
    }`}

  margin: 0;
`;

const Link = ({
  linktext,
  url,
  internallink,
  newwindow,
  asset,
  className = "",
  color,
  hoverColor,
  isUnderlined,
  isBold,
  padding,
  opacity,
  download,
  hoverUnderlined,
  onClick,
  isCurrent,
  fontSize,
}: Props) => {
  if (internallink) {
    const to = setSlug(url);
    return (
      <StyledGatsbyLink
        activeClassName="active"
        className={className}
        to={to}
        padding={padding}
        underlined={isUnderlined ? 1 : 0}
        opacity={opacity}
        onClick={onClick}
      >
        {asset ? (
          <ImageWrapper>
            <Image image={asset} />
          </ImageWrapper>
        ) : (
          <StyledP
            hoverColor={hoverColor}
            color={color}
            underlined={isUnderlined}
            hoverUnderlined={hoverUnderlined}
            fontWeight={isBold ? "bold" : "normal"}
            isCurrent={isCurrent}
            fontSize={fontSize}
          >
            {linktext}
          </StyledP>
        )}
      </StyledGatsbyLink>
    );
  }

  return (
    <A
      href={url ?? "/"}
      className={className}
      target={newwindow ? "_blank" : ""}
      rel={newwindow ? "noreferrer" : ""}
      color={color}
      opacity={opacity}
      underlined={isUnderlined}
      download={download}
    >
      {asset ? (
        <ImageWrapper>
          <Image image={asset} />
        </ImageWrapper>
      ) : (
        <StyledP fontSize={fontSize}>{linktext}</StyledP>
      )}
    </A>
  );
};

export default Link;
