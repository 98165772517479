import React, { useState } from "react";
import styled from "styled-components";

import { Maybe, SanityBlogs } from "@graphql-types";
import { Container, P } from "@util/standard";
import { DefaultButton, Image } from "@global";
import {
  colors,
  colorsRGB,
  fontSizes,
  fontWeights,
  IPAD_PRO_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { navigate } from "gatsby";

const Wrapper = styled(Container)`
  width: 84%;
  margin: 140px auto 120px auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 36px;
  row-gap: 4vh;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1.42fr 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 30px;
    width: 80%;
    margin: 50px auto;
  }
`;

const FeaturedContainer = styled(Container)`
  height: 40vh;
  position: relative;
  cursor: pointer;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 280px;
  }
`;

const Special = styled(Container)`
  grid-area: 1 / 2 / 5 / 5;
  height: 84vh;
  position: relative;
  cursor: pointer;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-area: 2 / 1 / 3 / 2;
    height: 400px;
  }
`;

const Overlay = styled.div<{ hoverEffect?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  transition: 0.5s;
  background-color: ${(props) =>
    props.hoverEffect ? `${colorsRGB.black(0.8)}` : `${colorsRGB.black(0.3)}`};
  overflow: hidden;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    background-color: ${(props) =>
      props.hoverEffect
        ? `${colorsRGB.black(0.8)}`
        : `${colorsRGB.black(0.4)}`};
  }
`;

const Title = styled(P)`
  font-size: ${fontSizes.h5.default}px;
  font-weight: ${fontWeights.bold};
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.h5.mobile}px;
    margin: 20px 0 8px 0;
  }
`;

const Summary = styled(P)`
  margin: 0px 0;
`;

const Content = styled(Container)<{
  hoverEffect?: boolean;
  height?: string;
  mobileHeight?: string;
}>`
  position: absolute;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  margin: -25px 0 0 50px;
  width: 80%;
  height: ${(props) => (props.height ? `${props.height}` : "84vh")};
  z-index: 2;
  h1,
  h2,
  h3,
  h4,
  h5,
  P {
    color: ${colors.white};
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 75%;
    margin: -15px 0 0 30px;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 80%;
    margin: -5px 0 0 30px;
    height: ${(props) =>
      props.mobileHeight ? `${props.mobileHeight}` : "400px"};
  }
`;

const ButtonContainer = styled(Container)<{ hoverEffect: boolean }>`
  visibility: hidden;
  opacity: 0;
  ${({ hoverEffect }) =>
    hoverEffect &&
    ` visibility: inherit; opacity: 1; width: 80%; margin:20px 0;`};

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    visibility: inherit;
    opacity: 1;
    margin: 20px 0;
  }
`;

interface Props {
  data: Maybe<SanityBlogs>[];
}

const BlogFeaturedNews = ({ data }: Props) => {
  if (data == null) return null;

  const [hoverEffectOn, setHoverEffectOn] = useState<number | undefined>();
  return (
    <Wrapper>
      {data.map((content, index) => {
        if (content == null) return null;

        const slug = `${content?.slug?.current}`;
        const handleClick = () => {
          navigate(slug);
        };

        const Info = () => {
          return (
            <Container onClick={handleClick} flexDirection="column">
              <Title>{content?.title}</Title>
              <Summary>{content?.summary}</Summary>
              <ButtonContainer hoverEffect={hoverEffectOn === index}>
                <DefaultButton
                  theme="text"
                  text="Read more"
                  linkTo={`blog/${slug}`}
                  padding="10px 40px"
                  arrowWidth="30px"
                  dimensions={{ height: "46px" }}
                />
              </ButtonContainer>
            </Container>
          );
        };

        if (index == 1) {
          return (
            <Special onClick={handleClick} key={content?._key}>
              <Overlay hoverEffect={hoverEffectOn === index} />
              <Image
                image={content?.cover}
                objectFit="cover"
                height="100%"
                width="100%"
              />
              <Content
                onMouseEnter={() => setHoverEffectOn(index)}
                onMouseLeave={() => setHoverEffectOn(99)}
                hoverEffect={hoverEffectOn === index}
              >
                <Info />
              </Content>
            </Special>
          );
        }
        return (
          <FeaturedContainer onClick={handleClick} key={index}>
            <Overlay hoverEffect={hoverEffectOn === index} />
            <Image
              image={content?.cover}
              objectFit="cover"
              height="100%"
              width="100%"
            />
            <Content
              height="40vh"
              mobileHeight="280px"
              onMouseEnter={() => setHoverEffectOn(index)}
              onMouseLeave={() => setHoverEffectOn(99)}
              hoverEffect={hoverEffectOn === index}
            >
              <Info />
            </Content>
          </FeaturedContainer>
        );
      })}
    </Wrapper>
  );
};

export default BlogFeaturedNews;
