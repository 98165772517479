const client = require("@sanity/client");

import imageUrlBuilder from "@sanity/image-url";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";

export const sanityClient = client({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID ?? "z6dd36gd",
  dataset: process.env.GATSBY_SANITY_DATASET ?? "production",
  useCdn: false,
  apiVersion: "2021-03-25",
});

export const imageBuilder = imageUrlBuilder(sanityClient).auto("format");

export function urlForImageRef(source?: SanityImageSource) {
  return imageBuilder.image(source ?? "");
}
