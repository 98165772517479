import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link as GatsbyLink, navigate } from "gatsby";
import { useSpring, animated } from "react-spring";
import { AnchorLink as GatsbyAnchorLink } from "gatsby-plugin-anchor-links";

import { ButtonTheme, Dimensions } from "@util/types";
import {
  assets,
  buttonStyle,
  fontSizes,
  MOBILE_BREAKPOINT,
} from "@util/constants";
import Loading from "./loading";
import { Container, GiveMeSomeSpace, P, A } from "@util/standard";
import { determineButtonTheme, setSlug } from "@util/helper";
import { Image } from "@global";
import { useStore } from "@state/store";

const StyledButton = styled.button<{
  theme: ButtonTheme;
  dimensions?: Dimensions;
  mobileDimensions?: Dimensions;
  disableHover?: boolean;
  mobileMargin?: string;
  margin?: string;
  minHeight?: number;
  width?: string;
  padding?: string;
  fontSize?: number;
}>`
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  text-align: center;
  cursor: pointer;
  user-select: none;
  ${({ margin }) => margin && `margin: ${margin};`}
  padding: ${(props) => (props.padding ? props.padding : `10px 35px`)};
  ${({ width }) => width && `width: ${width};`}
  height: ${(props) => props.dimensions?.height ?? `auto`};
  color: ${(props) => props.theme.text};
  border: 0.8px solid ${(props) => props.theme.border};
  border-radius: 30px;
  background-color: ${(props) => props.theme.bg};
  transition: background-color 0.4s linear;
  font-family: "Rubik", sans-serif;

  &:focus {
    outline: 0;
  }
  ${({ disableHover, theme }) =>
    !disableHover &&
    `&:hover {
      outline: 0;
      border-color: ${theme.hoverBorder};
      color: ${theme.hoverText};
      background-color: ${theme.hoverBg};
  }`}

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileDimensions }) => mobileDimensions && `width:${mobileDimensions}`}
    ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`}
    font-size: ${fontSizes.button.mobile}px;
    padding: ${(props) => (props.padding ? props.padding : `5px 30px`)};
  }

  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px;`}
`;

const LoadingContainer = styled(Container)`
  flex: 1;
  justify-content: center;
`;

const LinkBlock = styled(GatsbyLink)`
  &:link {
    text-decoration: none;
  }
`;

const StyledLink = styled(GatsbyAnchorLink)`
  text-decoration: none;
`;

const BlogArrow = styled(Container)`
  transform: rotate(180deg);
`;

const ArrowContainer = styled(Container)`
  transform: rotate(90deg);
  margin: 30px 0 0 0;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    transform: none;
    margin: -3px 10px;
  }
`;

interface Props {
  theme?: ButtonTheme;
  disabled?: boolean;
  className?: string;
  dimensions?: Dimensions;
  mobileDimensions?: Dimensions;
  minHeight?: number;
  text: string;
  margin?: string;
  padding?: string;
  disableHoverEffect?: boolean;
  mobileMargin?: string;
  onClick?: (args?: any) => void;
  loading?: boolean;
  type?: "button" | "submit" | "reset";
  linkTo?: string;
  linkToNewWindow?: string;
  width?: string;
  isOnDarkBackground?: boolean;
  arrowMargin?: string;
  arrowWidth?: string;
  fontSize?: number;
}

const DefaultButton = ({
  theme = "base",
  disabled,
  dimensions,
  width,
  mobileDimensions,
  text,
  margin,
  disableHoverEffect,
  onClick,
  mobileMargin,
  loading,
  minHeight,
  padding,
  type,
  linkTo,
  linkToNewWindow,
  isOnDarkBackground,
  arrowMargin,
  arrowWidth,
  fontSize,
}: Props) => {
  const [styles, api] = useSpring(() => ({
    from: { x: 5, opacity: 1 },
  }));
  const [hover, setHover] = useState(false);
  const buttonTheme = determineButtonTheme(theme, isOnDarkBackground);
  const { screenWidth } = useStore();
  useEffect(() => {
    if (hover) {
      api.start({
        x: 15,
        opacity: 1,
      });
    } else {
      api.start({
        x: 5,
        opacity: 1,
      });
    }
  }, [hover]);

  const Loader = () => (
    <LoadingContainer>
      <>
        <Loading />
        <GiveMeSomeSpace space={3} />
        <P noMargin color="white">
          Loading..
        </P>
      </>
    </LoadingContainer>
  );

  const AnimatedArrow = () => (
    <animated.div
      style={{
        ...styles,
      }}
    >
      <Container
        margin={arrowMargin ? `${arrowMargin}` : "auto auto auto 12px"}
      >
        <Image
          staticImage={
            buttonTheme === "text" || buttonTheme === "rotateText"
              ? assets.ctaArrow
              : assets.ctaArrowDark
          }
          altText="arrow"
          width={arrowWidth ? `${arrowWidth}` : "40px"}
        />
      </Container>
    </animated.div>
  );

  const handleOnClick = () => {
    if (loading) {
      return;
    }
    if (onClick) {
      onClick();
    }
  };

  const RenderedButton = ({
    externalLink,
  }: {
    externalLink?: string | undefined;
  }) => (
    <StyledButton
      theme={buttonStyle[theme]}
      dimensions={dimensions}
      width={width}
      disabled={disabled}
      onClick={handleOnClick}
      margin={margin}
      padding={padding}
      mobileDimensions={mobileDimensions}
      disableHover={loading || disableHoverEffect}
      mobileMargin={mobileMargin}
      minHeight={minHeight}
      type={type}
    >
      {loading ? (
        <Loader />
      ) : externalLink ? (
        <A
          href={externalLink}
          hoverColor="white"
          target="_blank"
          rel="noreferrer"
        >
          {text}
        </A>
      ) : (
        text
      )}
    </StyledButton>
  );

  if (
    buttonTheme === "text" ||
    buttonTheme === "textDark" ||
    buttonTheme === "blogBack"
  ) {
    const to = setSlug(linkTo);
    return (
      <Container
        flexDirection="row"
        display="inline-flex"
        cursor="pointer"
        alignItems="center"
        margin={margin}
        mobileMargin={mobileMargin}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={handleOnClick}
      >
        {buttonTheme === "blogBack" && (
          <BlogArrow
            onClick={() => {
              navigate(to);
            }}
          >
            <AnimatedArrow />
          </BlogArrow>
        )}
        <LinkBlock to={to}>
          <P
            margin="0"
            userSelect="none"
            fontSize={fontSize}
            fontWeight="normal"
            color={buttonTheme === "text" ? "white" : "black"}
          >{`${text} `}</P>
        </LinkBlock>
        {buttonTheme === "text" && <AnimatedArrow />}
        {buttonTheme === "textDark" && <AnimatedArrow />}
      </Container>
    );
  }

  if (buttonTheme === "rotateText") {
    return (
      <Container
        flexDirection={!screenWidth.isMobileWidth ? "column" : "row"}
        display="inline-flex"
        cursor="pointer"
        alignItems="flex-start"
        margin={margin}
        mobileMargin={mobileMargin}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={handleOnClick}
      >
        <StyledLink to={linkTo as string}>
          <P
            margin="0"
            userSelect="none"
            fontSize={fontSize}
            fontWeight={!screenWidth.isMobileWidth ? "bold" : "normal"}
            color="black"
          >{`${text} `}</P>
        </StyledLink>
        <ArrowContainer>
          <AnimatedArrow />
        </ArrowContainer>
      </Container>
    );
  }

  if (buttonTheme === "download") {
    return (
      <StyledButton
        theme={buttonStyle[theme]}
        dimensions={dimensions}
        width={width}
        disabled={disabled}
        onClick={handleOnClick}
        margin={margin}
        padding={padding}
        mobileDimensions={mobileDimensions}
        disableHover={loading || disableHoverEffect}
        mobileMargin={mobileMargin}
        minHeight={minHeight}
        type={type}
      >
        <Container
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <P margin="0 15px 0 0">{text}</P>
          <Image staticImage={assets.downloadDark} width="17px" height="19px" />
        </Container>
      </StyledButton>
    );
  }

  if (linkTo) {
    const link = linkTo.startsWith("/") ? linkTo : `/${linkTo}`;

    return (
      <GatsbyLink to={link}>
        <RenderedButton />
      </GatsbyLink>
    );
  }

  if (linkToNewWindow) {
    return <RenderedButton externalLink={linkToNewWindow} />;
  }
  return null;
};

export default DefaultButton;
