import React from "react";
import styled from "styled-components";

import { Container } from "@util/standard";
import { SanityTeamBlock } from "@graphql-types";
import { BlocksContent, Image } from "@global";
import {
  colors,
  IMAC_BREAKPOINT,
  IPAD_PRO_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";

const Wrapper = styled(Container)`
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    flex-direction: column;
    height: 100%;
  }
`;

const LeftContainer = styled(Container)`
  width: 49%;
  background-color: ${colors.footerGrey};
  align-items: center;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 100%;
    height: 245px;
  }
`;

const RightContainer = styled(Container)`
  width: 51%;
  background-color: ${colors.darkGrey};
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 55px 0;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 100%;
    height: 655px;
  }
`;

const ContentContainer = styled(Container)`
  width: 100%;
  margin: 0 0 0 42px;
  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${colors.white};
  }
  p {
    width: 70%;
    margin-block-end: 0px;
    margin-block-start: 5px;
  }
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    margin: 0 0 0 22px;
    p {
      line-height: 15.4px;
      width: 100%;
    }
  }
`;

const RightWrapper = styled(Container)`
  flex-direction: row;
  width: 44%;
  align-items: flex-start;
  margin: 25px 0 25px 0;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 40%;
    margin: 0 0 28px 0;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 55%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 70%;
  }
`;

const ImageContainer = styled(Container)`
  width: 18%;
  margin: 5px 0 0 0;
  @media only screen and (min-width: ${IMAC_BREAKPOINT}px) {
    width: 11%;
  }
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 10%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 15%;
  }
`;

interface Props {
  data: SanityTeamBlock;
}

const ValueBlock = ({ data }: Props) => {
  const { content, heading } = data;

  return (
    <Wrapper>
      <LeftContainer>
        <Container width="50%" margin="auto">
          <BlocksContent blocks={heading?._rawColumnContent} />
        </Container>
      </LeftContainer>
      <RightContainer>
        {content?.map((block) => {
          if (block == null) {
            return null;
          }
          return (
            <RightWrapper key={block?._key}>
              <ImageContainer>
                <Image image={block?.media} objectFit="contain" />
              </ImageContainer>
              <ContentContainer>
                <BlocksContent blocks={block?.content?._rawColumnContent} />
              </ContentContainer>
            </RightWrapper>
          );
        })}
      </RightContainer>
    </Wrapper>
  );
};

export default ValueBlock;
