import * as React from "react";
import styled from "styled-components";

import { Maybe, SanityLeftRightBlock } from "@graphql-types";
import { Container, P } from "@util/standard";
import { BlocksContent, DefaultButton, Image } from "@global";
import {
  colorsRGB,
  fontSizes,
  IPAD_PRO_BREAKPOINT,
  MOBILE_BREAKPOINT,
} from "@util/constants";

const LayoutContainer = styled(Container)<{
  imageReverse?: boolean;
  statement?: Maybe<boolean> | undefined;
  margin?: string;
}>`
  flex-direction: ${(props) => (props.imageReverse ? "row-reverse" : "row")};
  margin: ${(props) => (props.margin ? props.margin : "140px auto")};
  ${({ statement }) => statement && `background: ${colorsRGB.statement()};`}
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    flex-direction: column;
    margin: 40px auto;
  }
`;

const ContentContainer = styled(Container)<{
  statement?: Maybe<boolean> | undefined;
}>`
  flex-direction: column;
  width: ${(props) => (props.statement ? "30%" : "26%")};
  justify-content: center;
  margin: auto;

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 80%;
    margin: 10% auto;
    h4 {
      font-size: ${fontSizes.h4.mobile}px;
    }
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 15% auto;
  }
`;

const ImageContainer = styled(Container)<{
  statement?: Maybe<boolean> | undefined;
}>`
  width: ${(props) => (props.statement ? "40%" : "60%")};
  height: 83vh;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 100%;
    height: 44vh;
  }
`;

interface Props {
  data: SanityLeftRightBlock;
  margin?: string;
}

const LeftRightBlock = ({ data, margin }: Props) => {
  const {
    buttonLink,
    content,
    heading,
    imageReverse,
    media,
    statement,
    downloadFile,
  } = data;

  if (data == null) {
    return null;
  }

  const handleClick = () => {
    if (typeof window !== "undefined") {
      const url = downloadFile?.asset?.url as string;
      window.open(url, "_blank")?.focus();
    }
  };

  return (
    <LayoutContainer
      imageReverse={imageReverse as boolean}
      statement={statement}
      margin={margin}
    >
      <ImageContainer statement={statement}>
        <Image image={media} objectFit="cover" />
      </ImageContainer>
      <ContentContainer statement={statement}>
        <P>{heading}</P>
        <BlocksContent blocks={content?._rawColumnContent} />
        {buttonLink && !downloadFile && (
          <DefaultButton
            theme="base"
            text={`${buttonLink?.linktext}`}
            linkTo={`${buttonLink?.url}`}
            margin="10px 0 0 0"
            padding="10px 40px"
            dimensions={{ height: "46px" }}
          />
        )}
        {downloadFile && (
          <DefaultButton
            width="205px"
            theme="download"
            text="Download PDF"
            margin="10px 0 0 0"
            padding="10px 40px"
            dimensions={{ height: "46px" }}
            onClick={handleClick}
          />
        )}
      </ContentContainer>
    </LayoutContainer>
  );
};

export default LeftRightBlock;
