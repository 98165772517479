import React, { useState } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

import { Container, P } from "@util/standard";
import { Maybe, SanityLatestNewsBlock } from "@graphql-types";
import { DefaultButton, Image } from "@global";
import {
  colorsRGB,
  fontSizes,
  fontWeights,
  IPAD_PRO_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { useRecentBlogsQuery } from "@api";

const Wrapper = styled(Container)`
  width: 100%;
  height: 100%;
  margin: 140px auto 40px auto;
  align-items: center;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    overflow: hidden;
    margin: 70px auto 20px auto;
  }
`;

const Overlay = styled.div<{ hoverEffect?: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  transition: 0.5s;
  background-color: ${(props) =>
    props.hoverEffect ? `${colorsRGB.black(0.6)};` : `${colorsRGB.black(0)};`};
  overflow: hidden;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

const Header = styled(P)`
  font-size: ${fontSizes.h2.default}px;
  font-weight: ${fontWeights.bold};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: ${fontSizes.h2.mobile}px;
  }
`;

const ImageContainer = styled(Container)`
  width: 27vw;
  height: 54vh;
  position: relative;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80vw;
    height: 49vh;
  }
`;

const Title = styled(P)`
  font-size: ${fontSizes.h5.default}px;
  font-weight: ${fontWeights.bold};
  margin: 24px 0 16px 0px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: ${fontSizes.h5.mobile}px;
    margin: 20px 0 8px 0;
  }
`;

const Summary = styled(P)`
  margin: 0px 0;
`;

const TopContainer = styled(Container)`
  flex-direction: row;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const ArticlesContainer = styled(Container)`
  width: 27vw;
  flex-direction: row;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    width: 80vw;
  }
`;

const ButtonContainer = styled(Container)<{ hoverEffect: boolean }>`
  visibility: hidden;
  opacity: 0;
  margin: 15px 0 0 0px;
  ${({ hoverEffect }) => hoverEffect && ` visibility: inherit; opacity: 1;`};

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    visibility: inherit;
    opacity: 1;
    margin: 25px 0 49px 0;
  }
`;

interface Props {
  data: Maybe<SanityLatestNewsBlock> | undefined;
}

const LatestNewsBlock = ({ data }: Props) => {
  const recentArticles = useRecentBlogsQuery();
  const articlesToRender = data?.articles ?? recentArticles;
  const [hoverEffectOn, setHoverEffectOn] = useState<number | undefined>();

  return (
    <Wrapper>
      <Container
        width="85%"
        margin="auto"
        flexDirection="column"
        mobileWidth="80%"
      >
        <TopContainer>
          <Header>{data?.heading ?? "Latest News"}</Header>
          <DefaultButton
            theme="textDark"
            text={`${data?.buttonLinks?.linktext ?? "More Articles"}`}
            linkTo={`${data?.buttonLinks?.url ?? "blog"}`}
            margin="15px 0 0 20px"
            mobileMargin="-5px 0 20px 0"
            padding="10px 40px"
            dimensions={{ height: "46px" }}
            arrowWidth="23px"
          />
        </TopContainer>
        <ArticlesContainer>
          {articlesToRender?.map((content, index) => {
            if (content == null) {
              return null;
            }

            const slug = `/blog/${content?.slug?.current}`;
            const handleClick = () => {
              navigate(slug);
            };
            return (
              <Container
                margin="0 25px 10px 0"
                flexDirection="column"
                key={content?._id}
                onMouseEnter={() => setHoverEffectOn(index)}
                onMouseLeave={() => setHoverEffectOn(99)}
                cursor="pointer"
                onClick={handleClick}
              >
                <ImageContainer>
                  <Overlay hoverEffect={hoverEffectOn === index} />
                  <Image
                    image={content?.cover}
                    objectFit="cover"
                    width="100%"
                    height="100%"
                  />
                </ImageContainer>
                <Title>{content?.title}</Title>
                <Summary>{content?.summary}</Summary>
                <ButtonContainer hoverEffect={hoverEffectOn === index}>
                  <DefaultButton
                    theme="textDark"
                    text="Read more"
                    linkTo={`blog/${content?.slug?.current}`}
                    padding="10px 40px"
                    dimensions={{ height: "46px" }}
                    arrowWidth="23px"
                  />
                </ButtonContainer>
              </Container>
            );
          })}
        </ArticlesContainer>
      </Container>
    </Wrapper>
  );
};

export default LatestNewsBlock;
