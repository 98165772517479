import React from "react";
import styled from "styled-components";

import { SanityFullWidthImageBlock } from "@graphql-types";
import { Container, P } from "@util/standard";
import { BlocksContent, DefaultButton, Image } from "@global";
import {
  colors,
  IPAD_BREAKPOINT,
  IPAD_PRO_BREAKPOINT,
  MOBILE_BREAKPOINT,
  fontSizes,
} from "@util/constants";

const Overlay = styled.div<{}>`
  position: absolute;
  height: 94vh;
  width: 100%;
  z-index: 1;
  transition: 0.5s;
  background: linear-gradient(
    89.5deg,
    rgba(0, 0, 0, 0.66) 4.15%,
    rgba(0, 0, 0, 0) 99.64%
  );
  overflow: hidden;
`;

const ContentContainer = styled(Container)`
  position: absolute;
  margin: auto;
  width: 100%;
  height: 94vh;
  z-index: 2;
  h1,
  h2,
  h3,
  h4,
  h5,
  P {
    color: ${colors.white};
  }
`;

const LayoutContainer = styled(Container)`
  flex-direction: column;
  width: 80%;
  margin: auto;
  h1,
  h2,
  h3,
  h4,
  h5 {
    line-height: 32.2px;
  }
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 80%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0 0 38px 37px;
    justify-content: flex-end;
    h1,
    h2,
    h3,
    h4,
    h5 {
      line-height: 28px;
    }
    h4 {
      font-size: ${fontSizes.h4.mobile}px;
      margin-block-start: 0px;
      margin-block-end: 0px;
    }
  }
`;

const BlocksContainer = styled(Container)`
  width: 29%;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 38%;
  }
  @media only screen and (max-width: ${IPAD_BREAKPOINT}px) {
    width: 50%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 90%;
    p {
      margin-block-end: 0px;
    }
  }
`;

interface Props {
  data: SanityFullWidthImageBlock;
}

const FullWithImageBlock = ({ data }: Props) => {
  const { media, content, heading, buttonLink } = data;

  return (
    <Container width="100%" height="94vh" position="relative">
      <Overlay />
      <Image image={media} objectFit="cover" height="94vh" />
      <ContentContainer>
        <LayoutContainer>
          <P>{heading}</P>
          <BlocksContainer>
            <BlocksContent blocks={content?._rawColumnContent} />
          </BlocksContainer>
          {buttonLink && (
            <DefaultButton
              theme="white"
              text={`${buttonLink?.linktext}`}
              linkTo={`${buttonLink?.url}`}
              margin="10px 0 0 0"
              padding="10px 40px"
              dimensions={{ height: "46px" }}
            />
          )}
        </LayoutContainer>
      </ContentContainer>
    </Container>
  );
};

export default FullWithImageBlock;
