import { navigate } from "gatsby";
import { useState, useEffect } from "react";

import { useHeaderQuery, useAllPagePathsQuery } from "@api";
import { useStore } from "@state/store";
import { setRegion, setSlug, usePrevious } from "./helper";

// checks if region has changed then navigate to correct slug
export function useRegionChanged(
  slug: string | null | undefined,
  contextIban: string
) {
  const { region } = useStore();
  const previousIban = usePrevious(region.iban);
  const allPagePaths = useAllPagePathsQuery();
  const hasMounted = useHasMounted();

  const {
    allSanityRegion: { nodes },
  } = useHeaderQuery();
  const pageSlug = setSlug(slug);

  useEffect(() => {
    if (hasMounted) {
      if (contextIban !== region.iban) {
        const regionFound = nodes.find((node) => node.iban == contextIban);
        if (regionFound) {
          setRegion(regionFound);
        }
      }
    }
  }, [hasMounted]);

  useEffect(() => {
    if (region.iban !== previousIban) {
      if (allPagePaths.includes(pageSlug)) {
        navigate(pageSlug);
      } else {
        navigate(`/${region.slug}`);
      }
    }
  }, [region]);
}

// check if component has been mounted
export function useHasMounted() {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, []);

  if (!mounted) {
    return false;
  }

  return mounted;
}
