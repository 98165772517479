import React, { ReactNode } from "react";
import styled from "styled-components";
import { SanityFlightImage } from "@graphql-types";
import { SanityImage } from "sanity-image";

const StyledImage = styled(SanityImage)<{
  styleWidth?: string;
  styleHeight?: string;
  styleFit: string;
}>`
  ${({ styleWidth }) => (styleWidth ? `width:${styleWidth}` : "width: 100%;")};
  ${({ styleHeight }) =>
    styleHeight ? `height:${styleHeight}` : "height: 100%;"};
  ${({ styleFit }) => `object-fit: ${styleFit}`}
`;

const BackgroundImage = styled(SanityImage)<{}>`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const BackgroundImageWrapper = styled.div<{ minHeight?: string }>`
  width: 100%;
  margin: 0;
  position: relative;
  overflow: hidden;
  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}
`;

interface Props {
  image?: SanityFlightImage | null;
  staticImage?: string;
  objectFit?: "contain" | "cover" | "fill" | "scale-down" | "none";
  minHeight?: string;
  isBackground?: boolean;
  backgroundStyle?: any;
  width?: string;
  height?: string;
  altText?: string;
  children?: ReactNode;
  onClick?: () => void;
}

const Image = (props: Props) => {
  const { minHeight } = props;

  if (props.staticImage) {
    return (
      <img
        src={props.staticImage}
        width={props.width}
        height={props.height}
        style={{ minHeight }}
        itemProp="image"
        alt={props.altText ?? "image"}
      />
    );
  }

  if (props.image) {
    const { asset, hotspot } = props.image;
    //@ts-ignore
    const id = asset?._id ?? asset?._ref ?? "";
    const imgProps = {
      ...props.image,
      decoding: "async",
      alt: props.altText ?? "gatsby-image",
      onClick: props.onClick,
      id,
      width: 1000,
      baseUrl: `https://cdn.sanity.io/images/${process.env.GATSBY_SANITY_PROJECT_ID}/${process.env.GATSBY_SANITY_DATASET}/`,
      style: {
        objectPosition: hotspot
          ? `${Number(hotspot?.x) * 100}% ${Number(hotspot?.y) * 100}%`
          : "center",
      },
    };

    if (!id) return null;

    if (props.isBackground) {
      console.log(props, imgProps);
      return (
        <BackgroundImageWrapper minHeight={props.minHeight}>
          <BackgroundImage {...imgProps} />
          <BackgroundImageWrapper>{props.children}</BackgroundImageWrapper>
        </BackgroundImageWrapper>
      );
    }
    return (
      <StyledImage
        {...imgProps}
        styleWidth={props.width}
        styleHeight={props.height}
        styleFit={props.objectFit ?? "initial"}
      />
    );
  }

  return null;
};

export default Image;
