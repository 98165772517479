import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { Link as GatsbyLink } from "gatsby";

import { useLocation } from "@reach/router";
import { useHeaderQuery } from "@api";
import { useStore } from "@state/store";
import { getCountryCode, setRegion, setSlug } from "@util/helper";
import {
  DesktopLinksContainer,
  HeaderContainer,
  HeadWrapper,
  LogoContainer,
  MobileContainer,
  TopContainer,
} from "./shared.styles";
import { Button, Container } from "@util/standard";
import { Link, MenuDropdown, Image, MenuDrawer } from "@global";
import { Color } from "@util/types";
import { assets, colors, fontSizes } from "@util/constants";
import MobileMenu from "./mobileMenu";

interface RegionDropdownProps {
  color?: Color;
  placement?: "left" | "right";
}

const Header = () => {
  const { region, screenWidth } = useStore();
  const location = useLocation();
  const [hasScrolled, setHasScrolled] = useState(false);
  const isBrowser = () => typeof window !== "undefined";

  const {
    allSanityHeader: { nodes: headerNodes },
    allSanityRegion: { nodes: regionNodes },
  } = useHeaderQuery();

  const selectedHeader = headerNodes.filter(
    (node) => node.region?.iban === region.iban
  );

  const { otherLinks, logo, menuItems, buttonLink } = selectedHeader[0];
  const [hover, setHover] = useState(-1);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const blackBackground = () => {
    if (hasScrolled) {
      return true;
    }
    if (
      location.pathname.includes("blog/") ||
      location.pathname.includes("contact")
    ) {
      return true;
    }
    return false;
  };

  const handleScroll = () => {
    const scrollDistance =
      document.body.scrollTop + document.documentElement.scrollTop;

    if (scrollDistance > 10) {
      setHasScrolled(true);
    } else {
      setHasScrolled(false);
    }
  };

  useEffect(() => {
    if (process.env.GATSBY_NODE_ENV === "production") {
      getCountryCode().then((code) => {
        const regionFound = regionNodes.find((region) => region.iban == code);

        if (regionFound) {
          setRegion(regionFound);
        }
      });
    }

    if (typeof window !== "undefined") {
      handleScroll();
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const regionLength = regionNodes.filter(
    (region) => region.displayToggle == true
  );

  const RegionDropdown = ({ color, placement }: RegionDropdownProps) => (
    <MenuDropdown
      data={regionNodes}
      margin="0 40px 0 0"
      hover={hover === 10}
      hoverI={hover}
      setHover={setHover}
      hoverIndex={10}
      color={color ?? "white"}
      currentRegion={region}
      placement={placement}
    />
  );

  const handleLogoClick = () => {
    navigate(`/${region.slug}`);
  };

  const mobileCloseRegion = () => {
    if (!screenWidth.isTabletWidth && !screenWidth.isMobileWidth) {
      return;
    }
    if (hover === 10) {
      setHover(-1);
    }
  };

  return (
    <HeadWrapper hasScrolled={hasScrolled}>
      <TopContainer
        blackBackground={blackBackground()}
        hasScrolled={hasScrolled}
      >
        <Container
          width="100%"
          height="100%"
          margin="auto"
          alignItems="center"
          justifyContent="flex-end"
        >
          {otherLinks?.map((otherLink) => {
            if (otherLink == null) {
              return null;
            }

            const url = otherLink?.url === "/" ? "/" : `/${otherLink?.url}`;
            const isCurrent = isBrowser() && url === window.location.pathname;

            return (
              <Link
                {...otherLink}
                key={otherLink?._key}
                color={blackBackground() ? "white" : "blackGrey"}
                padding="0 40px 0 0"
                hoverColor="AISBlue"
                isCurrent={isCurrent}
                fontSize={fontSizes.subButton.default}
              />
            );
          })}

          {regionLength.length >= 2 && (
            <RegionDropdown
              placement="right"
              color={blackBackground() ? "white" : "blackGrey"}
            />
          )}
        </Container>
      </TopContainer>
      <HeaderContainer hasScrolled={hasScrolled}>
        <LogoContainer>
          <Image
            image={logo}
            altText={logo?.altText ?? "logo"}
            onClick={handleLogoClick}
            height="100%"
            width="100%"
            objectFit="contain"
          />
        </LogoContainer>
        <DesktopLinksContainer>
          {menuItems?.map((menuItem, index) => {
            if (menuItem == null) {
              return null;
            }

            const url = menuItem?.url === "/" ? "/" : `/${menuItem?.url}`;
            const isCurrent = isBrowser() && url === window.location.pathname;

            return (
              <Container
                key={menuItem?._key}
                onMouseEnter={() => setHover(index)}
                padding="0 20px"
              >
                <Link
                  {...menuItem}
                  color={blackBackground() ? "blackGrey" : "white"}
                  hoverUnderlined
                  isUnderlined={isCurrent}
                />
              </Container>
            );
          })}
        </DesktopLinksContainer>
        <DesktopLinksContainer>
          {buttonLink && (
            <GatsbyLink to={setSlug(buttonLink?.url)}>
              <Button
                color={blackBackground() ? `${colors.blackGrey}` : "white"}
                hoverColour={
                  blackBackground() ? "white" : `${colors.blackGrey}`
                }
                backgroundColor={
                  blackBackground() ? `${colors.AISBlue}` : "white"
                }
                borderColor={
                  blackBackground()
                    ? `${colors.AISBlue}`
                    : `${colors.blackGrey}`
                }
              >
                {buttonLink.linktext}
              </Button>
            </GatsbyLink>
          )}
        </DesktopLinksContainer>

        <MobileContainer>
          <img
            src={
              hasScrolled || blackBackground()
                ? assets.hamburgerDark
                : assets.hamburger
            }
            alt="hamburger"
            width="25px"
            height="auto"
            onClick={() => setDrawerVisible(true)}
          />
          <MenuDrawer
            visible={drawerVisible}
            onClose={() => {
              setDrawerVisible(false);
              setHover(-1);
            }}
            placement="right"
          >
            <MobileMenu
              regionDropdown={
                <div>
                  {regionLength.length >= 2 && (
                    <RegionDropdown color="blackGrey" />
                  )}
                </div>
              }
              handleDrawerClose={() => {
                setDrawerVisible(false);
                setHover(-1);
              }}
              menuItems={menuItems}
              otherLinks={otherLinks}
              onClick={() => mobileCloseRegion()}
            />
          </MenuDrawer>
        </MobileContainer>
      </HeaderContainer>
    </HeadWrapper>
  );
};

export default Header;
