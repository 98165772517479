import { Query } from "@graphql-types";
import { useStaticQuery, graphql } from "gatsby";

const DefaultSeoQuery = (iban: string) => {
  const {
    allSanityRegion: { nodes },
  }: Query = useStaticQuery(graphql`
    {
      allSanityRegion {
        nodes {
          iban
          defaultSEO {
            ...sanitySeo
          }
        }
      }
    }
  `);

  const region = nodes.find((node) => node.iban === iban);

  if (region == null) return null;

  return region.defaultSEO;
};

export default DefaultSeoQuery;
