import React, { ReactNode, useState, RefObject } from "react";
import ReactSlick, { Settings } from "react-slick";
import styled from "styled-components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { assets, MOBILE_BREAKPOINT } from "@util/constants";
interface Props {
  children: ReactNode;
  hideArrow?: boolean;
  moreSettings?: any;
  sliderRef?: RefObject<ReactSlick>;
  variableWidth?: boolean;
  adaptiveHeight?: boolean;
  infinite?: boolean;
  hideDots?: boolean;
  slidesToShow?: number;
  slidesToScroll?: number;
  autoplay?: boolean;
  autoplaySpeed?: number;
  afterChange?: (index: number) => void;
}

const Arrow = styled.img`
  object-fit: contain;
  width: 20px;
  height: 13px;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 15px;
  }
`;

const Carousel = ({
  children,
  hideArrow,
  sliderRef,
  variableWidth,
  adaptiveHeight,
  infinite,
  hideDots,
  slidesToShow,
  slidesToScroll,
  autoplay,
  autoplaySpeed,
  afterChange,
}: Props) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const settings: Settings = {
    dots: hideDots ? false : true,
    infinite: infinite ?? true,
    speed: 1000,
    autoplay: autoplay ?? true,
    autoplaySpeed: autoplaySpeed ?? 10000,
    slidesToShow: slidesToShow ?? 1,
    slidesToScroll: slidesToScroll ?? 1,
    arrows: hideArrow ? false : true,
    pauseOnHover: true,
    nextArrow: hideArrow ? undefined : (
      <Arrow src={assets?.nextArrow} alt={`next-index-${slideIndex + 1}`} />
    ),
    prevArrow: hideArrow ? undefined : (
      <Arrow src={assets?.preArrow} alt={`next-index-${slideIndex - 1}`} />
    ),
    beforeChange: (_current, next) => setSlideIndex(next),
    afterChange: (index) => afterChange && afterChange(index),
    variableWidth: variableWidth ? true : false,
    adaptiveHeight: adaptiveHeight ? true : false,
  };

  return (
    <ReactSlick ref={sliderRef} {...settings}>
      {children}
    </ReactSlick>
  );
};

export default Carousel;
