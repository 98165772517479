import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const FooterQuery = () => {
  const data: Query = useStaticQuery(graphql`
    {
      allSanityFooter {
        nodes {
          region {
            ...sanityRegion
          }
          footerLinks {
            ...sanityLink
          }
          quickLinks {
            ...sanityLink
          }
          getInTouchContent {
            buttonLink {
              ...sanityLink
            }
            blockContent {
              _rawContent
            }
          }
          location {
            _key
            _rawColumnContent
          }
          logoCarousel {
            ...sanityImageFullWidth
          }
        }
      }
      allSanityContact {
        nodes {
          region {
            ...sanityRegion
          }
          socialMediaIcon {
            socialIconWhite {
              ...sanityImage
            }
            mediaIcon {
              ...sanityImageFullWidth
            }
          }
        }
      }
    }
  `);

  return data;
};

export default FooterQuery;
