import React, { Dispatch, RefObject, SetStateAction } from "react";
import styled from "styled-components";

import { SanityRegion } from "@graphql-types";
import {
  Container,
  GiveMeSomeSpace,
  GridContainer,
  TextButton,
} from "@util/standard";
import { assets, colors, colorsRGB } from "@util/constants";
import { Color } from "@util/types";
import { useStore } from "@state/store";
import { Region } from "@state/types";
import { setRegion } from "@util/helper";

const AbsoluteWrapper = styled.div<{
  top?: number | null;
  height?: number;
  right?: number | null;
  placement: "left" | "right";
}>`
  display: flex;
  width: fit-content;
  position: absolute;
  ${(props) => (props.placement === "left" ? `left: 0` : `right: 0`)};
  top: ${(props) => (props.top ? props.top : 18)}px;
  padding: 30px;
  background-color: ${colors.white};
  overflow: auto;
  right: ${(props) => (props.right ? props.right : -10)}px;
  box-shadow: 0 15px 10px 0 ${colorsRGB.black(0.1)};
`;

const LinkContainer = styled(Container)`
  position: relative;
  align-items: center;
`;

interface Props {
  data: SanityRegion[];
  margin?: string;
  hover: boolean | undefined;
  hoverI: number;
  setHover: Dispatch<SetStateAction<number>>;
  hoverIndex: number;
  color?: Color;
  currentRegion?: Region;
  placement?: "left" | "right";
  heightTarget?: RefObject<HTMLDivElement>;
}

const MenuDropDown = ({
  data,
  margin,
  hover,
  hoverI,
  setHover,
  hoverIndex,
  color,
  currentRegion,
  placement = "left",
  heightTarget,
}: Props) => {
  const downIcon = color === "blackGrey" ? assets.arrowDownDark : assets.arrowDown;
  const upIcon = color === "blackGrey" ? assets.arrowUpDark : assets.arrowUp;
  const { screenWidth } = useStore();

  const updateHover = (value: number) => {
    setHover(value);
  };

  const mobileClick = () => {
    if (screenWidth.isTabletWidth || screenWidth.isMobileWidth) {
      if (hoverI === -1) {
        updateHover(hoverIndex);
      } else {
        updateHover(-1);
      }
    }
  };

  const enterUpdate = () => {
    if (!screenWidth.isTabletWidth && !screenWidth.isMobileWidth) {
      updateHover(hoverIndex);
    }
  };

  const Dropdown = () => {
    return (
      <AbsoluteWrapper
        placement={placement}
        onMouseLeave={() => updateHover(-1)}
        top={heightTarget?.current && heightTarget.current.offsetHeight}
      >
        <GridContainer repeat={1} tabletRepeat={1} rowGap={15}>
          {data?.map((region) => {
            if (region == null) {
              return null;
            }
            const handleClick = () => {
              setRegion(region);
            };

            return (
              <div>
                {region.displayToggle && 
                  (<TextButton
                    key={region.title}
                    bold={region.iban === currentRegion?.iban}
                    hoverColor="AISBlue"
                    onClick={handleClick}
                    color="blackGrey"
                  >
                    {region.displayTitle}
                  </TextButton>)}
              </div>
            );
          })}
        </GridContainer>
      </AbsoluteWrapper>
    );
  };

  return (
    <LinkContainer
      margin={margin}
      onMouseEnter={enterUpdate}
      onClick={mobileClick}
    >
      <TextButton color={color ?? "blackGrey"}>
        {data.find((region) => region.iban === currentRegion?.iban)?.title}
      </TextButton>
      <GiveMeSomeSpace space={5} />
      <img width="8px" height="5px" src={hover ? upIcon : downIcon} />

      {hover && <Dropdown />}
    </LinkContainer>
  );
};

export default MenuDropDown;
