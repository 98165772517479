import React, { useState } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

import { SanityFeatureBlockArray } from "@graphql-types";
import { Container } from "@util/standard";
import { BlocksContent, DefaultButton, Image } from "@global";
import {
  colors,
  colorsRGB,
  IPAD_PRO_BREAKPOINT,
  MOBILE_BREAKPOINT,
} from "@util/constants";

const Wrapper = styled(Container)`
  width: 86%;
  height: 72vh;
  margin: 60px auto;
  flex-direction: row;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    flex-direction: column;
    height: 100%;
    margin: 40px auto;
  }
`;

const FeaturedContainer = styled(Container)`
  position: relative;
  width: 100%;
  margin: 20px 20px;
  cursor: pointer;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 100%;
    height: 330px;
    margin: 13px auto;
  }
`;

const Overlay = styled.div<{ hoverEffect?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  transition: 0.5s;
  background-color: ${(props) =>
    props.hoverEffect ? `${colorsRGB.black(0.8)}` : `${colorsRGB.black(0.4)}`};
  overflow: hidden;
`;

const ContentContainer = styled(Container)<{
  hoverEffect?: boolean;
}>`
  position: absolute;
  flex-direction: column;
  margin: auto;
  width: 100%;
  z-index: 2;
  bottom: 10px;
  justify-content: flex-end;
  ${({ hoverEffect }) => hoverEffect && `transform: translateY(-5vh);`};
  h1,
  h2,
  h3,
  h4,
  h5,
  P {
    color: ${colors.white};
  }
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 100%;
    justify-content: center;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 60px auto;
  }
`;

const BlocksContentContainer = styled(Container)`
  width: 80%;
  margin: 40px auto -30px auto;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    margin: 0 auto;
  }
`;

const ButtonContainer = styled(Container)<{ hoverEffect: boolean }>`
  visibility: hidden;
  opacity: 0;
  ${({ hoverEffect }) =>
    hoverEffect &&
    ` visibility: inherit; opacity: 1; width: 80%; margin:10px auto 0 auto;`};

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    visibility: inherit;
    opacity: 1;
    margin: 6px auto 0 auto;
    width: 80%;
  }
`;

interface Props {
  data: SanityFeatureBlockArray;
  baseSlug?: string;
}

const TabBlock = ({ data, baseSlug }: Props) => {
  const { featureBlock } = data;
  const [hoverEffectOn, setHoverEffectOn] = useState<number | undefined>();
  return (
    <Wrapper>
      {featureBlock?.map((content, index) => {
        if (content == null) {
          return null;
        }
        const slug = `#${content?.anchoredId}`;
        const handleClick = () => {
          navigate(slug);
        };

        return (
          <FeaturedContainer key={content?._key} onClick={handleClick}>
            <Overlay hoverEffect={hoverEffectOn === index} />
            <Image
              image={content?.media}
              objectFit="cover"
              height="100%"
              width="100%"
            />
            <ContentContainer
              onMouseEnter={() => setHoverEffectOn(index)}
              onMouseLeave={() => setHoverEffectOn(99)}
              hoverEffect={hoverEffectOn === index}
            >
              <BlocksContentContainer>
                <BlocksContent
                  blocks={content?.blockContent?._rawColumnContent}
                />
              </BlocksContentContainer>
              <ButtonContainer hoverEffect={hoverEffectOn === index}>
                {content?.anchoredId && (
                  <DefaultButton
                    theme="rotateText"
                    text={`${content?.anchoredTitle}`}
                    linkTo={`/${baseSlug}#${content?.anchoredId}`}
                    padding="10px 40px"
                    margin="1vw 0 0 0"
                    mobileMargin="0"
                    arrowMargin="3px -20px 0 0"
                    arrowWidth="30px"
                    dimensions={{ height: "46px" }}
                  />
                )}
              </ButtonContainer>
            </ContentContainer>
          </FeaturedContainer>
        );
      })}
    </Wrapper>
  );
};

export default TabBlock;
