// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-blog-landing-template-tsx": () => import("./../../../src/templates/blogLandingTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-landing-template-tsx" */),
  "component---src-templates-career-template-tsx": () => import("./../../../src/templates/careerTemplate.tsx" /* webpackChunkName: "component---src-templates-career-template-tsx" */),
  "component---src-templates-community-template-tsx": () => import("./../../../src/templates/communityTemplate.tsx" /* webpackChunkName: "component---src-templates-community-template-tsx" */),
  "component---src-templates-construction-template-tsx": () => import("./../../../src/templates/constructionTemplate.tsx" /* webpackChunkName: "component---src-templates-construction-template-tsx" */),
  "component---src-templates-contact-template-tsx": () => import("./../../../src/templates/contactTemplate.tsx" /* webpackChunkName: "component---src-templates-contact-template-tsx" */),
  "component---src-templates-home-template-tsx": () => import("./../../../src/templates/homeTemplate.tsx" /* webpackChunkName: "component---src-templates-home-template-tsx" */),
  "component---src-templates-offshore-support-template-tsx": () => import("./../../../src/templates/offshoreSupportTemplate.tsx" /* webpackChunkName: "component---src-templates-offshore-support-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/pageTemplate.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */),
  "component---src-templates-power-template-tsx": () => import("./../../../src/templates/powerTemplate.tsx" /* webpackChunkName: "component---src-templates-power-template-tsx" */),
  "component---src-templates-recruitment-template-tsx": () => import("./../../../src/templates/recruitmentTemplate.tsx" /* webpackChunkName: "component---src-templates-recruitment-template-tsx" */),
  "component---src-templates-what-we-do-template-tsx": () => import("./../../../src/templates/whatWeDoTemplate.tsx" /* webpackChunkName: "component---src-templates-what-we-do-template-tsx" */)
}

