import React, { ReactNode, useEffect } from "react";
import styled from "styled-components";
import { globalHistory } from "@reach/router";

import { Link } from "@global";
import { assets, fontSizes } from "@util/constants";
import { Container } from "@util/standard";
import { Maybe, SanityLink } from "@graphql-types";

const Wrapper = styled(Container)`
  flex-direction: column;
  margin: 16vh 0 0 15%;
  p {
      font-size: ${fontSizes.h4.mobile}px;
      letter-spacing: 1px;
      line-height: 18.2px;
  }
  a {
    white-space:break-spaces;
  }
`;

interface Props {
  regionDropdown: ReactNode;
  handleDrawerClose: () => void;
  menuItems: Maybe<Maybe<SanityLink>[]> | undefined;
  otherLinks: Maybe<Maybe<SanityLink>[]> | undefined;
  onClick?: () => void;
}

const MobileMenu = ({
  regionDropdown,
  handleDrawerClose,
  menuItems,
  otherLinks,
  onClick,
}: Props) => {

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        handleDrawerClose();
      }
    });
  }, []);

  const MainMenu = () => (
    <Wrapper onClick={() => {
      if (onClick) {
        onClick();
      }
    }}>
      {menuItems?.map(menuItem => {
        if (menuItem == null) {
          return null;
        }
        return (
          <Container key={menuItem?._key} margin="5px 0">
            <Link
              {...menuItem}
              color="blackGrey"
              padding="10px 0"
              onClick={handleDrawerClose}
            />
          </Container>
        );
      })}
      <Container flexDirection="column" margin="30px 0 0 0">
        {otherLinks?.map(otherlink => {
          if (otherlink == null) {
            return null;
          }
          return (
            <Container key={otherlink?._key} margin="5px 0">
              <Link
                {...otherlink}
                color="blackGrey"
                padding="10px 0"
                onClick={handleDrawerClose}
              />
            </Container>
          );
        })}
      </Container>
    </Wrapper>
  );

  return (
    <Container
      flexDirection="column"
      margin="20px 30px"
      justifyContent="space-between"
      height="90%"
      overflow="auto"
    >
      <Container flexDirection="column">
        <Container justifyContent="space-between">
          {regionDropdown}
          <img
            src={assets.close}
            alt="close"
            width="15px"
            height="15px"
            onClick={handleDrawerClose}
          />
        </Container>
        <MainMenu />
      </Container>
    </Container>
  );
};

export default MobileMenu;
