export const BUTTON_HEIGHT = 46;
export const HEADER_HEIGHT = 84;

// screen sizes
export const SMALLER_MOBILE = 400;
export const MOBILE_BREAKPOINT = 600;
export const IPAD_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1000;
export const IPAD_PRO_BREAKPOINT = 1025;
export const SMALL_LAPTOP_BREAKPOINT = 1280;
export const LAPTOP_BREAKPOINT = 1441;
export const TWENTY_INCH_SCREEN = 1600;
export const IMAC_BREAKPOINT = 1921;
export const DEFAULT_OFFSET = 120;
export const MOBILE_OFFSET = 60;

export const CONTENT_MAX_WIDTH = 1440;

export const colors = {
  transparent: "transparent",
  white: "#fff",
  black: "#000",
  AISBlue: "#01AAFF",
  darkGrey: "#2C2D31",
  lightGrey: "#BDBEC0",
  blackGrey: "#3B3C40",
  errorRed: "#FF0000",
  carouselBlack: "#2C2D31",
  underlineColor: "#C4C4C4",
  footerGrey:"#ECECED",
};

export const fontWeights = {
  normal: 400,
  semibold: 600,
  bold: 700,
  extrabold: 800,
};

export const PRIMARY_COLOR = colors.AISBlue;
export const SECONDARY_COLOR = colors.black;

export const fontSizes = {
  p: { default: 15, mobile: 13, lineHeight: 18.2 },
  h1: { default: 75, mobile: 40, lineHeight:97.5, mobileLineHeight:52},
  h2: { default: 45, mobile: 30, lineHeight:63, mobileLineHeight:42 },
  h3: { default: 30, mobile: 26, lineHeight:45, mobileLineHeight:39 },
  h4: { default: 23, mobile: 20, lineHeight:32.2, mobileLineHeight: 28 },
  h5: { default: 20, mobile: 14, lineHeight:28 },
  span: { default: 16, mobile: 12 },
  button: { default: 13, mobile: 13 },
  subButton: { default: 12, mobile: 12 },
};

export const colorsRGB = {
  flightBlue: (opacity?: number) => `rgba(0, 28, 168, ${opacity ?? "0.4"})`,
  black: (opacity?: number) => `rgba(0, 0, 0, ${opacity ?? "0.2"})`,
  white: (opacity?: number) => `rgba(255, 255, 255, ${opacity ?? "0.3"})`,
  lightGrey: (opacity?: number) => `rgba(189, 190, 192, ${opacity ?? "0.3"})`,
  carouselBlue: (opacity?: number) => `rgba(1, 170, 255, ${opacity ?? "0.05"})`,
  statement: (opacity?: number) => `rgba(189, 190, 192, ${opacity ?? "0.2"})`,
  squareGrey: (opacity?: number) => `rgba(147, 149, 152, ${opacity ?? "0.1"})`,
  squareBlack: (opacity?: number) => `rgba(59, 60, 64, ${opacity ?? "0.2"})`,
  squareBlue: (opacity?: number) => `rgba(1, 170, 255, ${opacity ?? "0.5"})`,
};

export const pages = {
  home: "/",
  aboutUs: "/about-us",
  contactUs: "/contact-us",
  search: "/search",
  notFound: "/404",
};

// define paths to asset
export const assets = {
  logo: require("@static/assets/logo.png").default,
  arrowDown: require("@static/assets/arrow-down.svg").default,
  arrowUp: require("@static/assets/arrow-up.svg").default,
  arrowDownDark: require("@static/assets/arrow-down-dark.svg").default,
  arrowUpDark: require("@static/assets/arrow-up-dark.svg").default,
  hamburger: require("@static/assets/hamburger.svg").default,
  hamburgerDark: require("@static/assets/hamburger-dark.svg").default,
  close: require("@static/assets/close.svg").default,
  facebook: require("@static/assets/facebook.png").default,
  linkedin: require("@static/assets/linkedin.png").default,
  ctaArrow: require("@static/assets/cta-arrow.png").default,
  ctaArrowDark: require("@static/assets/cta-arrow-dark.svg").default,
  preArrow: require("@static/assets/pre-arrow.png").default,
  nextArrow: require("@static/assets/next-arrow.svg").default,
  downloadDark: require("@static/assets/download-dark.svg").default,
};

export const buttonStyle = {
  base: {
    bg: `${colors.transparent}`,
    text: `${colors.blackGrey}`,
    border: `${colors.blackGrey}`,
    hoverBg: `${colors.AISBlue}`,
    hoverBorder: `${colors.AISBlue}`,
    hoverText: `${colors.white}`,
  },
  white: {
    bg: `${colors.transparent}`,
    text: `${colors.white}`,
    border: `${colors.white}`,
    hoverBg: `${colors.white}`,
    hoverBorder: `${colors.white}`,
    hoverText: `${colors.blackGrey}`,
  },
  whiteInverse: {
    bg: `${colors.white}`,
    text: `${colors.blackGrey}`,
    border: `${colors.white}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.white}`,
    hoverText: `${colors.white}`,
  },
  text: {},
  textDark: {},
  download: {
    bg: `${colors.transparent}`,
    text: `${colors.blackGrey}`,
    border: `${colors.blackGrey}`,
    hoverBg: `${colors.AISBlue}`,
    hoverBorder: `${colors.AISBlue}`,
    hoverText: `${colors.blackGrey}`,
  },
  rotateText: {},
  blogBack: {},
};
