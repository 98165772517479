import React from "react";
import styled from "styled-components";

import { Container } from "@util/standard";
import { SanityBlockContent } from "@graphql-types";
import { ColorContent } from "@global";
import { fontSizes, IPAD_PRO_BREAKPOINT, MOBILE_BREAKPOINT } from "@util/constants";

const Wrapper = styled(Container) <{ reduceBottomSpace?: boolean; }>`
  align-items: center;
  margin: ${props => (!props.reduceBottomSpace ? "auto" : "0 auto -100px auto")};
  width: 66%; 
  padding: 170px 0;
  h2 {
    line-height: 45px;
  }
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 80%;
    margin: 5vh auto;
    padding: 55px 0;
    h2 {
      font-size: ${fontSizes.h2.mobile}px;
      line-height: ${fontSizes.h2.mobileLineHeight}px;
    }
  }
`;

interface Props {
  data: SanityBlockContent;
  isHome?: boolean;
}

const ParagraphBlock = ({ data, isHome }: Props) => {
  const { reduceBottomSpace } = data;

  return (
    <Wrapper reduceBottomSpace={reduceBottomSpace !== false ? true : false}>
      <ColorContent
        data={data}
      />
    </Wrapper>
  );
};

export default ParagraphBlock;
