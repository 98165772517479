import React from "react";
import styled from "styled-components";

import { Maybe, SanityFlightImage } from "@graphql-types";
import { Container } from "@util/standard";
import { Carousel, Image } from "@global";
import { useStore } from "@state/store";
import {
  IMAC_BREAKPOINT,
  IPAD_PRO_BREAKPOINT,
  LAPTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";

const Wrapper = styled(Container)<{ height?: string }>`
  width: 75%;
  margin: 100px auto;
  height: 100%;
  align-items: center;
  .slick-prev {
    left: -50px;
    z-index: 2;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      left: -20px;
    }
  }
  .slick-next {
    right: -50px;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      right: -20px;
    }
  }
  .slick-slider {
    width: 100%;
  }
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 85%;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 75%;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 50px auto;
  }
`;

const ImageContainer = styled(Container)`
  height: 140px;
  width: 140px !important;
  display: flex !important;
  justify-content: center;
  margin: auto;
  @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
    width: 108px !important;
    height: 108px;
  }
`;

interface Props {
  data: Maybe<SanityFlightImage>[];
}

const CarouselWithLogo = ({ data }: Props) => {
  const { screenWidth } = useStore();

  return (
    <Wrapper>
      <Carousel
        hideDots
        slidesToShow={
          screenWidth.isIpadProWidth || screenWidth.isTabletWidth
            ? 4
            : screenWidth.isMobileWidth
            ? 2
            : 7
        }
      >
        {data.map((image) => {
          if (image == null) return null;

          return (
            <ImageContainer key={image._key}>
              <Image
                image={image?.asset}
                objectFit="contain"
                width="100%"
                height="100%"
              />
            </ImageContainer>
          );
        })}
      </Carousel>
    </Wrapper>
  );
};

export default CarouselWithLogo;
