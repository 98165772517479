import create from "zustand";
import { persist, devtools } from "zustand/middleware";
import { Region, ScreenWidth } from "./types";

interface State {
  region: Region;
  screenWidth: ScreenWidth;
}

const defaultRegion = {
  iban: "nz",
  hrefLang: "en-nz",
  slug: "",
};

export const defaultScreenWidth = {
  isTabletWidth: false,
  isMobileWidth: false,
  isLaptopWidth: false,
  isIpadProWidth: false,
};

export const useStore = create(
  devtools(
    persist<State>(
      () => ({
        region: defaultRegion,
        screenWidth: defaultScreenWidth,
      }),
      {
        name: "sb-store", // unique name
        getStorage: () => sessionStorage, // (optional) by default the 'localStorage' is used
      }
    )
  )
);
