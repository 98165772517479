import React, { useState } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

import { SanityFeatureBlockArray } from "@graphql-types";
import { Container } from "@util/standard";
import { BlocksContent, DefaultButton, Image } from "@global";
import { colors, colorsRGB, IPAD_PRO_BREAKPOINT } from "@util/constants";

const Wrapper = styled(Container)`
  width: 100%;
  height: 73vh;
  flex-direction: row;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    flex-direction: column;
    height: 100%;
  }
`;

const FeaturedContainer = styled(Container)<{ columnNumber?: number }>`
  width: calc(100% / ${(props) => props.columnNumber});
  cursor: pointer;
  position: relative;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 100%;
    height: 250px;
  }
`;

const Overlay = styled.div<{ hoverEffect?: boolean }>`
  position: absolute;
  height: 73vh;
  width: 100%;
  z-index: 1;
  transition: 0.5s;
  background-color: ${(props) =>
    props.hoverEffect ? `${colorsRGB.black(0.8)}` : `${colorsRGB.black(0.4)}`};
  overflow: hidden;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 100%;
    height: 250px;
    background-color: ${colorsRGB.black(0.6)};
  }
`;

const ContentContainer = styled(Container)<{ hoverEffect?: boolean }>`
  position: absolute;
  flex-direction: column;
  margin: auto;
  width: 100%;
  height: 73vh;
  z-index: 2;
  justify-content: flex-end;
  ${({ hoverEffect }) => hoverEffect && `transform: translateY(-5vh);`};
  h1,
  h2,
  h3,
  h4,
  h5,
  P {
    color: ${colors.white};
  }
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 100%;
    height: 250px;
    justify-content: center;
  }
`;

const BlocksContentContainer = styled(Container)`
  width: 80%;
  margin: 40px auto;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    margin: 0 auto;
  }
`;

const ButtonContainer = styled(Container)<{ hoverEffect: boolean }>`
  visibility: hidden;
  opacity: 0;
  ${({ hoverEffect }) =>
    hoverEffect &&
    ` visibility: inherit; opacity: 1; width: 80%; margin:10px auto 0 auto;`};

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    visibility: inherit;
    opacity: 1;
    margin: 6px auto 0 auto;
    width: 80%;
  }
`;

interface Props {
  data: SanityFeatureBlockArray;
}

const FeaturedBlock = ({ data }: Props) => {
  const { featureBlock } = data;
  const [hoverEffectOn, setHoverEffectOn] = useState<number | undefined>();
  return (
    <Wrapper>
      {featureBlock &&
        featureBlock.map((content, index) => {
          if (content == null) {
            return null;
          }
          return (
            <FeaturedContainer
              key={content?._key}
              onClick={() => {
                if (content?.buttonLinks?.url)
                  navigate(`${content?.buttonLinks?.url}`);
              }}
              columnNumber={featureBlock.length}
            >
              <Overlay hoverEffect={hoverEffectOn === index} />
              <Image
                image={content?.media}
                objectFit="cover"
                height="100%"
                width="100%"
              />
              <ContentContainer
                onMouseEnter={() => setHoverEffectOn(index)}
                onMouseLeave={() => setHoverEffectOn(99)}
                hoverEffect={hoverEffectOn === index}
              >
                <BlocksContentContainer>
                  <BlocksContent
                    blocks={content?.blockContent?._rawColumnContent}
                  />
                </BlocksContentContainer>
                <ButtonContainer hoverEffect={hoverEffectOn === index}>
                  {content?.buttonLinks?.linktext && (
                    <DefaultButton
                      theme="white"
                      text={`${content?.buttonLinks?.linktext}`}
                      linkTo={`${content?.buttonLinks?.url}`}
                      padding="10px 40px"
                      dimensions={{ height: "46px" }}
                    />
                  )}
                </ButtonContainer>
              </ContentContainer>
            </FeaturedContainer>
          );
        })}
    </Wrapper>
  );
};

export default FeaturedBlock;
