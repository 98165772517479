import styled, { createGlobalStyle } from "styled-components";

import { colors, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { fontSizes, fontWeights } from "@util/constants";

export const GlobalStyle = createGlobalStyle`

  body {
    margin: 0px;
    font-family: 'Rubik', sans-serif;
    font-size: ${fontSizes.p.default}px;
    font-weight: ${fontWeights.normal};
    color: ${colors.blackGrey};
    overflow-x: hidden;
    line-height:${fontSizes.p.lineHeight}px;
  }

  h1, h2, h3, h4, h5{
    line-height: 1.2;
    span{
      padding: 0.2em 0;
    }
  }
  h1, .h1 {
    font-size: ${fontSizes.h1.default}px;
    font-weight: ${fontWeights.bold};
  }
  h2, .h2 {
    font-size: ${fontSizes.h2.default}px;
    font-weight: ${fontWeights.bold};
    margin: 10px 0;
  }
  h3, .h3 {
    font-size: ${fontSizes.h3.default}px;
    font-weight: ${fontWeights.bold};
    margin: 10px 0;
  }
  h4, .h4 {
    font-size: ${fontSizes.h4.default}px;
    font-weight: ${fontWeights.bold};
    margin: 0;
  }
  h5, .h5 {
    font-size: ${fontSizes.h5.default}px;
    font-weight: ${fontWeights.bold};
  }
  button {
    font-size: ${fontSizes.button.default}px;
    font-weight: ${fontWeights.normal};
  }

  a {
    user-select: none;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${colors.blackGrey};

    &:hover {
      color: ${colors.AISBlue};
    }
  }

  iframe {
    border: 0;
    height: 100%;
    width: 100%;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    body {
    font-size: ${fontSizes.p.mobile}px;
    }
    h1, .h1 {
      font-size: ${fontSizes.h1.mobile}px;
    }
    h2, .h2 {
      font-size: ${fontSizes.h2.mobile}px;
    }
    h3, .h3 {
      font-size: ${fontSizes.h3.mobile}px;
    }
    h4, .h4 {
      font-size: ${fontSizes.h4.mobile}px;
    }
    h5, .h5 {
      font-size: ${fontSizes.h5.mobile}px;
    }
  }

`;

export const Main = styled.main<{}>`
  overflow-x: hidden;
`;
