import React, { useState } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

import { Maybe, SanityBlogs } from "@graphql-types";
import { Container, P } from "@util/standard";
import { DefaultButton, Image } from "@global";
import {
  colorsRGB,
  fontSizes,
  fontWeights,
  IPAD_PRO_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { filterCorrectBlogs } from "@util/helper";

const Wrapper = styled(Container)`
  width: 84%;
  margin: 70px auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  column-gap: 25px;
  row-gap: 100px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 8px;
    row-gap: 40px;
  }
`;

const Overlay = styled.div<{ hoverEffect?: boolean }>`
  position: absolute;
  height: 54vh;
  width: 27vw;
  z-index: 1;
  transition: 0.5s;
  background-color: ${(props) =>
    props.hoverEffect ? `${colorsRGB.black(0.6)};` : `${colorsRGB.black(0)};`};
  overflow: hidden;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

const ImageContainer = styled(Container)`
  width: 27vw;
  height: 54vh;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    height: 224px;
  }
`;

const Title = styled(P)`
  font-size: ${fontSizes.h5.default}px;
  font-weight: ${fontWeights.bold};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: ${fontSizes.h5.mobile}px;
    margin: 20px 0 8px 0;
  }
`;

const Summary = styled(P)`
  margin: 0px 0;
`;

const ButtonContainer = styled(Container)<{ hoverEffect: boolean }>`
  visibility: hidden;
  opacity: 0;
  ${({ hoverEffect }) => hoverEffect && ` visibility: inherit; opacity: 1;`};

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    visibility: inherit;
    opacity: 1;
  }
`;

interface Props {
  data: Maybe<SanityBlogs>[];
}

const BlogOtherNews = ({ data }: Props) => {
  if (data == null) return null;

  const blogs = filterCorrectBlogs(data);

  const [hoverEffectOn, setHoverEffectOn] = useState<number | undefined>();
  return (
    <Wrapper>
      {blogs.map((content, index) => {
        if (content == null) return null;

        const slug = `/blog/${content?.slug?.current}`;
        const handleClick = () => {
          navigate(slug);
        };

        return (
          <Container
            flexDirection="column"
            onClick={handleClick}
            key={content?.id}
            onMouseEnter={() => setHoverEffectOn(index)}
            onMouseLeave={() => setHoverEffectOn(99)}
            cursor="pointer"
          >
            <Overlay hoverEffect={hoverEffectOn === index} />
            <ImageContainer>
              <Image
                image={content?.cover}
                objectFit="cover"
                width="100%"
                height="100%"
              />
            </ImageContainer>
            <Title>{content?.title}</Title>
            <Summary>{content?.summary}</Summary>
            <ButtonContainer hoverEffect={hoverEffectOn === index}>
              <DefaultButton
                theme="textDark"
                text="Read more"
                linkTo={slug}
                margin="15px 0 0 0px"
                padding="10px 40px"
                dimensions={{ height: "46px" }}
                arrowWidth="23px"
              />
            </ButtonContainer>
          </Container>
        );
      })}
    </Wrapper>
  );
};

export default BlogOtherNews;
