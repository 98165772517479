import React from "react";
import styled from "styled-components";

import { SanityGetInTouchBlock } from "@graphql-types";
import { Container } from "@util/standard";
import { colors, TABLET_BREAKPOINT } from "@util/constants";
import { ColorContent, DefaultButton } from "@global";

const Wrapper = styled(Container)`
  background-color: ${colors.carouselBlack};
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
  flex-direction: column;
  padding: 130px 0 100px;
  h1,h2,h3,h4,p{
    color: white;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px){
    padding: 55px 0;
  }
`;

interface Props {
  data: SanityGetInTouchBlock;
}

const GetInTouchBlock = ({ data }: Props) => {
  const { blockContent, buttonLink } = data;

  return (
    <Wrapper >
      <Container width="70%" mobileWidth="78%" justifyContent="center" >
        <ColorContent data={blockContent} />
      </Container>
      <DefaultButton
        theme="white"
        text={`${buttonLink?.linktext}`}
        linkTo={`/${buttonLink?.url}`}
        margin="20px 0 0 0"
        mobileMargin="10px 0 0 0"
        padding="10px 40px"
        dimensions={{ height: "46px" }}
      />
    </Wrapper>
  );
};

export default GetInTouchBlock;
