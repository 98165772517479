import React, { createElement } from "react";
import styled from "styled-components";
import PortableText from "@sanity/block-content-to-react";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";

import { Link } from "@global";
import {
  LAPTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  TWENTY_INCH_SCREEN,
} from "@util/constants";
import { Container } from "@util/standard";
import { urlForImageRef } from "@lib/sanityClient";

interface Props {
  blocks: any;
  fontSize?: number;
  isHero?: boolean;
}

const Img = styled(Container)<{ url?: string }>`
  margin: 20px 0px 35px 0px;
  height: 700px;
  width: 100%;
  ${({ url }) => url && `background-image: url(${url})`};
  background-position: center;
  background-repeat: no-repeat;

  @media only screen and (max-width: ${TWENTY_INCH_SCREEN}px) {
    height: 600px;
  }

  @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
    height: 500px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 400px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 300px;
    margin: 20px 0px;
  }
`;

const AnimateNumber = styled.span`
  display: inline-block;
`;

const BlocksContent = ({ blocks, isHero }: Props) => {
  const BlockRenderer = (props: any) => {
    const { style = "normal" } = props.node;
    const tag = style;
    if (/^h\d/.test(tag)) {
      if (isHero) {
        return createElement("h1", { className: tag }, props.children);
      } else {
        return createElement(tag, {}, props.children);
      }
    }
    return <p>{props.children}</p>;
  };

  const ImageRenderer = (props: any) => {
    return <Img url={urlForImageRef(props.node.asset).url() as string} />;
  };

  const LinkRenderer = (props: any) => {
    return (
      <Link
        {...props.node}
        opacity={0.5}
        className={
          props.node.linkDisplay ? props.node.linkDisplay : "custom-link"
        }
      />
    );
  };

  const animation = (props: any) => {
    return (
      <VisibilitySensor>
        {({ isVisible }) => (
          <AnimateNumber>
            {isVisible ? (
              <CountUp end={parseInt(props.children[0])} duration={1} />
            ) : (
              0
            )}
          </AnimateNumber>
        )}
      </VisibilitySensor>
    );
  };

  return (
    <PortableText
      blocks={blocks}
      serializers={{
        marks: { animation },
        types: {
          block: BlockRenderer,
          flightImage: ImageRenderer,
          link: LinkRenderer,
        },
      }}
      imageOptions={{ auto: "format", fit: "fill" }}
      projectId={process.env.SANITY_PROJECT_ID}
      dataset={process.env.GATSBY_NODE_ENV ?? "development"}
    />
  );
};

export default BlocksContent;
