import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";
import { useStore } from "@state/store";

const regionQuery = () => {
  const iban = useStore.getState().region.iban;

  const { allSanityRegion }: Query = useStaticQuery(graphql`
    {
      allSanityRegion {
        nodes {
          ...sanityRegion
        }
      }
    }
  `);

  const selectedRegion = allSanityRegion.nodes.filter(
    region => region.iban === iban
  );

  return selectedRegion[0];
};

export default regionQuery;
