import styled from "styled-components";
import { CSSProperties } from "react";

import {
  colors,
  colorsRGB,
  fontSizes,
  fontWeights,
  PRIMARY_COLOR,
} from "./constants";
import { FontWeights, Color, Dimensions } from "./types";
import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT } from "@util/constants";

export const Overlay = styled.div<{ zIndex?: number }>`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: ${(props) => props.zIndex ?? 3};
  left: 0;
  top: 0;
  background-color: ${colorsRGB.black(0.5)};
  overflow: hidden;
  transition: 0.5s;
`;

export const P = styled.p<{
  color?: Color;
  fontSize?: number;
  noMargin?: boolean;
  marginTop?: number;
  marginBottom?: number;
  width?: number;
  lineHeight?: number;
  underlined?: boolean;
  fontWeight?: FontWeights;
  opacity?: number;
  isMobileStyling?: boolean;
  display?: CSSProperties["display"];
  whiteSpace?: CSSProperties["whiteSpace"];
  cursor?: CSSProperties["cursor"];
  padding?: string;
  textAlign?: string;
  letterSpacing?: string;
  margin?: string;
  userSelect?: string;
}>`
  text-decoration-line: ${(props) => (props.underlined ? "underline" : "none")};
  background-color: transparent;
  font-weight: ${(props) =>
    props.fontWeight ? fontWeights[props.fontWeight] : fontWeights.normal};
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${letterSpacing};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ userSelect }) => userSelect && `user-select: ${userSelect};`}
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}px;`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ noMargin }) => noMargin && `margin: 0px;`}
  ${({ width }) => width && `width: ${width}px;`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ display }) => display && `display: ${display};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.p.mobile}px;
    line-height: ${fontSizes.p.mobile * 1.2}px;
  }

  ${({ isMobileStyling }) =>
    isMobileStyling &&
    `@media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 80%;
    text-align: center;
    margin: auto;
  }`}
`;

export const A = styled.a<{
  color?: Color;
  underlined?: boolean;
  bold?: boolean;
  hoverColor?: Color;
  opacity?: number;
  margin?: string;
}>`
  color: ${(props) => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  ${({ underlined }) =>
    underlined ? "text-decoration: underline;" : "text-decoration: none;"}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ bold }) => bold && "font-weight: bold;"}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ hoverColor }) =>
    hoverColor &&
    `&:hover {
    color: ${colors[hoverColor]};
  }`}
`;

export const H1 = styled.h1<{
  noMargin?: boolean;
  fontWeight?: string;
  fontSize?: number;
  color?: Color;
}>`
  color: ${(props) => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  font-size: ${(props) => props.fontSize ?? fontSizes.h1.default}px;
  ${({ noMargin }) => noMargin && `margin: 0px;`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.h1.mobile}px;
    line-height: ${fontSizes.h1.mobile * 1.2}px;
  }
`;
export const H2 = styled.h2<{
  color?: Color;
  margin?: string;
  fontWeight?: FontWeights;
  fontSize?: number;
  mobileFontSize?: number;
}>`
  font-size: ${fontSizes.h2.default}px;
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`};
  white-space: initial;
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.h2.mobile}px;

    ${({ mobileFontSize }) =>
      mobileFontSize && `font-size: ${mobileFontSize}px;`};
    line-height: ${fontSizes.h2.mobile * 1.2}px;
  }
`;

export const H3 = styled.h3<{
  color?: Color;
  margin?: string;
  fontWeight?: FontWeights;
  fontSize?: number;
  width?: string;
  tabletWidth?: string;
}>`
  font-size: ${fontSizes.h3.default}px;
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px`};
  ${({ width }) => width && `width: ${width};`};
  white-space: initial;
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ tabletWidth }) => tabletWidth && `width: ${tabletWidth};`};
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.h3.mobile}px;
    line-height: ${fontSizes.h3.mobile * 1.2}px;
  }
`;

export const H4 = styled.h4<{
  color?: Color;
  margin?: string;
  fontWeight?: FontWeights;
}>`
  font-size: ${fontSizes.h4.default}px;
  white-space: initial;
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.h4.mobile}px;
    line-height: ${fontSizes.h4.mobile * 1.2}px;
  }
`;

export const VerticalSeparator = styled.div<{
  color?: Color;
  width?: string;
  height?: string;
  opacity?: number;
  shouldShowMobile?: boolean;
}>`
  border-left: 1.5px solid
    ${(props) => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  height: ${(props) => (props.height ? props.height : `auto`)};
  width: ${(props) => (props.width ? props.width : `20px`)};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
`;

export const Separator = styled.div<{
  color?: Color;
  height?: string;
  opacity?: number;
  thickness?: number;
  shouldHideDesktop?: boolean;
  marginBottom?: number;
  width?: string;
}>`
  ${({ marginBottom }) =>
    marginBottom ? `margin-bottom: ${marginBottom}px;` : `margin-bottom: 10px`};
  border-bottom: ${(props) => props.thickness ?? 1}px solid
    ${(props) => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  ${({ width }) => width && `width: ${width ?? "100%"};`}
  height: ${(props) => props.height ?? `20px`};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}

  ${({ width }) => width && `width: ${width};`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ shouldHideDesktop }) => shouldHideDesktop && `display:block`}
  }
  @media only screen and (min-width: ${TABLET_BREAKPOINT}px) {
    ${({ shouldHideDesktop }) => shouldHideDesktop && `display:none`}
  }
`;

export const Container = styled.div<{
  backgroundColor?: Color;
  backgroundRGBColor?: string;
  flexDirection?: CSSProperties["flexDirection"];
  flex?: number | string;
  flexGrow?: number;
  flexWrap?: CSSProperties["flexWrap"];
  justifyContent?: CSSProperties["justifyContent"];
  alignItems?: CSSProperties["alignItems"];
  alignSelf?: CSSProperties["alignSelf"];
  mobileAlignSelf?: CSSProperties["alignSelf"];
  borderRadius?: number;
  overflow?: CSSProperties["overflow"];
  paddingVertical?: number;
  paddingHorizontal?: number;
  img?: string;
  isMobileColumn?: boolean;
  mobilePadding?: string;
  tabletWidth?: string;
  mobileWidth?: string;
  mobileJustifyContent?: CSSProperties["justifyContent"];
  mobileDimensions?: Dimensions;
  tabletDimensions?: Dimensions;
  width?: string;
  maxWidth?: string;
  height?: string;
  opacity?: number;
  margin?: string;
  mobileMargin?: string;
  padding?: string;
  cursor?: CSSProperties["cursor"];
  hideOnMobile?: boolean;
  hideOnTablet?: boolean;
  showOnTablet?: boolean;
  zIndex?: number;
  display?: string;
  position?: CSSProperties["position"];
}>`
  display: ${(props) => props.display ?? "flex"};
  flex-direction: ${(props) => props.flexDirection ?? "initial"};
  justify-content: ${(props) => props.justifyContent ?? "initial"};
  align-items: ${(props) => props.alignItems ?? "initial"};
  align-self: ${(props) => props.alignSelf ?? "initial"};
  overflow: ${(props) => props.overflow ?? "initial"};
  border-radius: ${(props) => props.borderRadius ?? 0}px;
  background-size: cover;
  ${({ position }) => position && `position: ${position};`}
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
  ${({ flex }) => flex && `flex: ${flex};`}
  ${({ flexGrow }) => flexGrow && `flex-grow: ${flexGrow};`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ paddingVertical }) =>
    paddingVertical &&
    `padding: 0px ${paddingVertical}px 0px ${paddingVertical}px;`}
    ${({ paddingHorizontal }) =>
    paddingHorizontal &&
    `padding: ${paddingHorizontal}px 0px ${paddingHorizontal}px 0px;`}
  ${({ img }) => img && `background-image: url(${img});`}
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${colors[backgroundColor]};`}
  ${({ backgroundRGBColor }) =>
    backgroundRGBColor && `background-color: ${backgroundRGBColor};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`};
  ${({ height }) => height && `height: ${height};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ showOnTablet }) => showOnTablet && `display: none;`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ isMobileColumn }) =>
      isMobileColumn &&
      `
      flex-direction: column;
      height: auto;
      align-items: center;
      width: 100%;
    `}

    ${({ mobilePadding }) => mobilePadding && `padding: ${mobilePadding}`}

    ${({ tabletDimensions }) =>
      tabletDimensions &&
      tabletDimensions.height &&
      `height: ${tabletDimensions.height};`}
  ${({ tabletDimensions }) =>
      tabletDimensions &&
      tabletDimensions.width &&
      `width: ${tabletDimensions.width};`}

      ${({ hideOnTablet }) => hideOnTablet && `display: none;`}
      ${({ showOnTablet }) => showOnTablet && `display: flex !important;`}
      ${({ tabletWidth }) => tabletWidth && `width: ${tabletWidth};`};
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileDimensions }) =>
      mobileDimensions &&
      mobileDimensions.height &&
      `height: ${mobileDimensions.height};`}
    ${({ mobileDimensions }) =>
      mobileDimensions &&
      mobileDimensions.width &&
      `width: ${mobileDimensions.width};`}

    ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`}
    ${({ mobileJustifyContent }) => mobileJustifyContent && `justify-content: ${mobileJustifyContent};`}
    ${({ hideOnMobile }) => hideOnMobile && `display: none;`}
    ${({ mobileAlignSelf }) =>
      mobileAlignSelf && `align-self: ${mobileAlignSelf}`}
      ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`};
  }
`;

export const GridContainer = styled.div<{
  gridTemplateColumns?: string;
  width?: string;
  margin?: string;
  repeat?: number;
  mobileRepeat?: number;
  tabletRepeat?: number;
  columnGap?: number;
  rowGap?: number;
  mobileColumnGap?: number;
  mobileRowGap?: number;
  rtl?: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.repeat ?? 4}, 1fr);
  ${({ columnGap }) => columnGap && `grid-column-gap: ${columnGap}px;`}
  ${({ rowGap }) => rowGap && `grid-row-gap: ${rowGap}px;`}
  ${({ width }) => width && `width: ${width};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ rtl }) => rtl && `direction: rtl;`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    grid-template-columns: repeat(${(props) => props.tabletRepeat ?? 2}, 1fr);
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(${(props) => props.mobileRepeat ?? 1}, 1fr);
    ${({ mobileColumnGap }) =>
      mobileColumnGap && `grid-column-gap: ${mobileColumnGap}px;`}
    ${({ mobileRowGap }) => mobileRowGap && `grid-row-gap: ${mobileRowGap}px;`}
  }
`;

export const TextButton = styled(P)<{
  color?: Color;
  hoverColor?: Color;
  underlined?: boolean;
  lineHeight?: number;
  margin?: string;
  bold?: boolean;
}>`
  ${({ margin }) => margin && `margin: ${margin};`}
  line-height: ${(props) => props.lineHeight ?? 18}px;
  font-size: ${fontSizes.p.default}px;
  text-decoration-line: ${(props) => (props.underlined ? "underline" : "none")};
  color: ${(props) => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  cursor: pointer;
  margin: 0;
  user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: ${(props) =>
    props.bold ? fontWeights.bold : fontWeights.normal};

  &:focus {
    outline: 0;
  }
  &:hover {
    color: ${(props) =>
      props.hoverColor ? colors[props.hoverColor] : colors.AISBlue};
  }
`;

export const GiveMeSomeSpace = styled.div<{ space?: number }>`
  padding: ${(props) => props.space ?? 10}px;
`;

export const Button = styled.button<{
  color?: string;
  hoverColour?: string;
  padding?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
  border?: string;
  backgroundColor?: string;
  borderColor?: string;
  margin?: string;
}>`
  width: ${(props) => (props.width ? props.width : "116px")};
  height: ${(props) => (props.height ? props.height : "36px")};
  padding: ${(props) => (props.padding ? props.padding : "10px 5px")};
  background-color: transparent;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "20px"};
  border: ${(props) => (props.border ? props.border : "1px solid")};
  border-color: ${(props) => (props.color ? props.color : colors.white)};
  ${({ margin }) => margin && `margin: ${margin};`};
  font-family: "Rubik", sans-serif;
  font-weight: ${fontWeights.normal};
  letter-spacing: 0.08em;
  font-size: ${fontSizes.button.default}px;
  cursor: pointer;
  color: ${(props) => (props.color ? props.color : colors.white)};

  &:hover {
    color: ${(props) => props.hoverColour ?? colors.black};
    border-color: ${(props) => (props.borderColor ? props.borderColor : colors.white)};
    background-color: ${(props) => (props.color ? props.backgroundColor : colors.white)};
  }
`;
