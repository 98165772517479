import React, { ReactNode, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import Header from "./header";
import Footer from "./footer";
import { IPAD_PRO_BREAKPOINT, LAPTOP_BREAKPOINT, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { objectEquality, usePrevious } from "@util/helper";
import { defaultScreenWidth, useStore } from "@state/store";
import { GlobalStyle, Main } from "../styles/globalStyles";
interface Props {
  children: ReactNode;
}

function Layout(props: Props) {
  const { children } = props;

  const [screenWidth, setScreenWidth] = useState(defaultScreenWidth);
  const prevScreenWidth = usePrevious(screenWidth);

  const checkScreenWidth = () => {
    if (window.innerWidth <= MOBILE_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isMobileWidth: true,
      });
      return;
    }

    if (window.innerWidth <= TABLET_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isTabletWidth: true,
      });
      return;
    }

    if (window.innerWidth <= IPAD_PRO_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isIpadProWidth: true,
      });
      return;
    }
    if (window.innerWidth <= LAPTOP_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isLaptopWidth: true,
      });
      return;
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      checkScreenWidth();
      window.addEventListener("resize", checkScreenWidth);
    }

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  useEffect(() => {
    if (!objectEquality(prevScreenWidth, screenWidth)) {
      useStore.setState({ screenWidth });
    }
  }, [screenWidth]);

  return (
    <div>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Rubik:wght@400;700;800;900&display=swap" rel="stylesheet" />
      </Helmet>
      <GlobalStyle />
      <Header />
      <Main>{children}</Main>
      <Footer />
    </div>
  );
}

export default Layout;
