import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const HeaderQuery = () => {
  const headerData: Query = useStaticQuery(graphql`
    {
      allSanityHeader {
        nodes {
          logo {
            ...sanityImageFullWidth
          }
          menuItems {
            ...sanityLink
          }
          otherLinks {
            ...sanityLink
          }
          region {
            ...sanityRegion
          }
          buttonLink {
            ...sanityLink
          }
        }
      }
      allSanityRegion {
        nodes {
          ...sanityRegion
        }
      }
    }
  `);

  return headerData;
};

export default HeaderQuery;
