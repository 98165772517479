import React from "react";
import styled from "styled-components";

import { Container } from "@util/standard";
import { SanityTeamBlock } from "@graphql-types";
import { IPAD_PRO_BREAKPOINT, MOBILE_BREAKPOINT } from "@util/constants";
import { BlocksContent, Image } from "@global";

const Wrapper = styled(Container)`
  height: 100%;
  align-items: center;
  margin: 140px auto 20px auto;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 100px auto 20px auto;
  }
`;

const ImageContainer = styled(Container)`
  background-position: top;
`;

const StaffContainer = styled(Container)`
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  margin: 96px auto 0px auto;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 40px auto 0px auto;
  }
`;

const Staff = styled(Container)`
  width: calc(25% - 34px);
  margin: 0 17px 120px 17px;
  flex-direction: column;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: calc(50% - 34px);
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: calc(50% - 8px);
    margin: 0 4px 40px 4px;
  }
`;

interface Props {
  data: SanityTeamBlock;
}

const TeamBlock = ({ data }: Props) => {
  const { content, heading } = data;

  return (
    <Wrapper>
      <Container width="86%" margin="auto" flexDirection="column">
        <Container margin="0 0 0 17px" mobileMargin="0 0 0 4px" width="93%">
          <BlocksContent blocks={heading?._rawColumnContent} />
        </Container>
        <StaffContainer>
          {content?.map((staff) => {
            if (staff == null) {
              return null;
            }
            return (
              <Staff key={staff?._key}>
                <ImageContainer>
                  <Image image={staff?.media} objectFit="cover" height="100%" />
                </ImageContainer>
                <Container margin="24px 0">
                  <BlocksContent blocks={staff?.content?._rawColumnContent} />
                </Container>
              </Staff>
            );
          })}
        </StaffContainer>
      </Container>
    </Wrapper>
  );
};

export default TeamBlock;
